import React, {useCallback, useContext, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {TableChip} from "../components/EnhancedTableToolbar";
import {transactionFeedbackOptions} from "../constants";
import {dateRange, DateRangeConfig, DayOfWeek, getLabelForPeriod} from "../helpers/dateUtils";
import {EN_GB} from "../helpers/helpers";
import useSavedField from "../helpers/useSavedField";
import {TransactionReportFilters} from "../models/transactions";
import ValidationError from "../models/validationError";
import ConfirmDiscardDialog from "../screens/transactions/components/ConfirmDiscardDialog";
import {SettingsContext} from "./SettingsContext";
import {UserContext} from "./UserContext";


const defaultFiltersDummy: TransactionReportFilters = {
    period: "today",
    locationArea: undefined,
    location: undefined,
    device: undefined,
    paymentMethod: undefined,
    staff: undefined
};

const TransactionFiltersContext = React.createContext({
    filters: defaultFiltersDummy,
    unsavedFilters: defaultFiltersDummy,
    defaultFilters: defaultFiltersDummy,
    setFilters: (f: (prevFilters: TransactionReportFilters) => TransactionReportFilters) => { /* no op */
    },
    validateFilters: (() => false) as () => boolean,
    applyFilters: (() => false) as () => boolean,
    resetFilters: () => { /* no op */
    },
    filtersChanged: false,
    filtersOpen: false,
    openFilters: () => { /* no  op */
    },
    closeFilters: (force?: boolean) => { /* no  op */
    },
    dateRangeConfig: {
        weeksStartOn: "monday" as DayOfWeek,
        daysStartAt: 0,
        useLocalTime: true
    } as DateRangeConfig,
    validationErrors: [] as ValidationError[],
    chips: [] as TableChip[]
});

const TransactionFiltersContextProvider = ({children}: { children: React.ReactNode }) => {
    const {settings} = useContext(SettingsContext);

    const dateRangeConfig: DateRangeConfig = useMemo(() => ({
        weeksStartOn: settings.weeksStart as unknown as DayOfWeek,
        daysStartAt: parseInt(settings.daysStart, 10),
        useLocalTime: true
    }), [settings]);

    const defaultFilters: TransactionReportFilters = useMemo(() => ({
        period: "today",
        periodFrom: dateRange("today", undefined, undefined, undefined, dateRangeConfig).startDate,
        periodTo: dateRange("today", undefined, undefined, undefined, dateRangeConfig).endDate,
        locationArea: undefined,
        location: undefined,
        device: undefined,
        paymentMethod: undefined,
        staff: undefined,
        hasActiveFlag: true
    }), [dateRangeConfig]);

    const {
        val: unsavedFilters,
        setVal: internalSetFilters,
        committed: filters,
        changed: filtersChanged,
        apply: internalApplyFilters,
        reset: internalResetFilters,
        forceSet: forceSetFilters
    } = useSavedField<TransactionReportFilters>(defaultFilters);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const {t} = useTranslation();
    const {user} = useContext(UserContext);

    const setFilters = useCallback((f: (prevFilters: TransactionReportFilters) => TransactionReportFilters) => {
        internalSetFilters((prevFilters) => {
            const newFilters = f(prevFilters);
            if (newFilters.period !== prevFilters.period && newFilters.period !== "custom") {
                const range = dateRange(newFilters.period, undefined, undefined, undefined, dateRangeConfig);
                return {
                    ...newFilters,
                    periodFrom: range.startDate,
                    periodTo: range.endDate
                };
            }
            if (newFilters.periodFrom !== prevFilters.periodFrom || newFilters.periodTo !== prevFilters.periodTo) {
                return {
                    ...newFilters,
                    period: "custom"
                }
            }
            return newFilters;
        })
    }, [dateRangeConfig, internalSetFilters]);

    const validateFilters = useCallback(() => {
        const errors = [];

        // date checks
        const maxDayLimitHours = 31 * 24;
        const validFrom = !Number.isNaN(Date.parse(unsavedFilters.periodFrom || ""));
        const validTo = !Number.isNaN(Date.parse(unsavedFilters.periodTo || ""));
        let dateGap;

        if (!validFrom) {
            errors.push({field: "periodFrom", message: t("components.datePicker.enterValidDate")});
        }
        if (!validTo) {
            errors.push({field: "periodTo", message: t("components.datePicker.enterValidDate")});
        }
        if (validFrom && validTo) {
            dateGap = new Date(unsavedFilters.periodTo || "").getTime() - new Date(unsavedFilters.periodFrom || "").getTime()
        }
        if (dateGap && dateGap < 0) {
            errors.push({field: "periodFrom", message: t("components.datePicker.startDateMayNotBeAfterEnd")});
        }
        // Converts the filter's milliseconds to hours and compares to 31 days worth of hours 
        if (dateGap && (dateGap)/(1000 * 60 * 60) > maxDayLimitHours ){
            errors.push({field: "periodTo", message: t("components.datePicker.dateRangeLimitedToOneMonth")});
        }

        // guid check
        const validCompanyGuid = !unsavedFilters.companyGuid || /[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}/i.test(unsavedFilters.companyGuid);

        if (!validCompanyGuid) {
            errors.push({field: "companyGuid", message: t("components.filters.companyIdInvalid")});
        }

        setValidationErrors(errors);
        return errors.length === 0;
    }, [unsavedFilters, setValidationErrors, t]);

    const applyFilters = useCallback(() => {
        if (!validateFilters()) {
            return false;
        }
        setValidationErrors([]);
        internalApplyFilters();
        return true;
    }, [validateFilters, setValidationErrors, internalApplyFilters]);

    const resetFilters = useCallback(() => {
        setValidationErrors([]);
        internalResetFilters();
    }, [setValidationErrors, internalResetFilters]);

    const openFilters = useCallback(() => {
        setFiltersOpen(true);
    }, [setFiltersOpen]);

    const closeFilters = useCallback((force = false) => {
        if (filtersChanged && !force) {
            setDialogOpen(true);
            return;
        }
        setFiltersOpen(false);
    }, [setDialogOpen, setFiltersOpen, filtersChanged]);

    const resetPeriod = useCallback(() => {
        forceSetFilters((prevFilters) => ({
            ...prevFilters,
            period: defaultFilters.period,
            periodFrom: defaultFilters.periodFrom,
            periodTo: defaultFilters.periodTo,
        }));
    }, [forceSetFilters, defaultFilters]);

    const resetGuid = useCallback(() => {
        forceSetFilters((prevFilters) => ({
            ...prevFilters,
            companyGuid: undefined
        }));
    }, [forceSetFilters]);

    const resetFlags = useCallback(() => {
        forceSetFilters((prevFilters) => ({
            ...prevFilters,
            activeFlagCategory: undefined,
            hasActiveFlag: undefined,
        }));
    }, [forceSetFilters]);

    const resetLocation = useCallback(() => {
        forceSetFilters((prevFilters) => ({
            ...prevFilters,
            location: undefined,
        }));
    }, [forceSetFilters]);

    const resetDevice = useCallback(() => {
        forceSetFilters((prevFilters) => ({
            ...prevFilters,
            device: undefined,
        }));
    }, [forceSetFilters]);


    const resetStaff = useCallback(() => {
        forceSetFilters((prevFilters) => ({
            ...prevFilters,
            staff: undefined,
        }));
    }, [forceSetFilters]);


    const resetLocationArea = useCallback(() => {
        forceSetFilters((prevFilters) => ({
            ...prevFilters,
            locationArea: undefined,
        }));
    }, [forceSetFilters]);


    const resetPaymentMethod = useCallback(() => {
        forceSetFilters((prevFilters) => ({
            ...prevFilters,
            paymentMethod: undefined,
        }));
    }, [forceSetFilters]);

    const getLabelForFlag = useCallback((category?: keyof typeof transactionFeedbackOptions | "all" | "allFlagged") => {
        if (category === "all") {
            return t("components.filters.allTransactions");
        }
        if (category !== "allFlagged" && category) {
            return t(`screens.transactions.feedback.options.${category}`);
        }
        return t("components.filters.allFlaggedTransactions");
    }, [t]);

    const context = useMemo(() => {
        const chips = [
            {
                label: getLabelForPeriod(filters.period, filters.periodFrom, filters.periodTo, t, user?.UILanguageTag || EN_GB),
                onDelete: filters.period === defaultFilters.period ? undefined : resetPeriod
            },
            ...(filters.companyGuid ? [{
                label: `${t("screens.transactions.fields.companyId")}: ${filters.companyGuid}`,
                onDelete: resetGuid,
                adminView: true
            }] : []),
            ...(filters.activeFlagCategory || filters.hasActiveFlag ? [{
                label: `${t("screens.transactions.feedback.title")}: ${getLabelForFlag(filters.activeFlagCategory)}`,
                onDelete: resetFlags,
                adminView: true
            }] : []),
            ...(filters.location ? [{
                label: `${t("screens.transactions.fields.location")}: ${filters.location.name}`,
                onDelete: resetLocation,
            }] : []),
            ...(filters.device ? [{
                label: `${t("screens.transactions.fields.device")}: ${filters.device.name}`,
                onDelete: resetDevice,
            }] : []),
            ...(filters.paymentMethod ? [{
                label: `${t("screens.transactions.fields.paymentMethod")}: ${filters.paymentMethod.name}`,
                onDelete: resetPaymentMethod,
            }] : []),
            ...(filters.staff ? [{
                label: `${t("screens.transactions.fields.staff")}: ${filters.staff.name}`,
                onDelete: resetStaff,
            }] : []),
            ...(filters.locationArea ? [{
                label: `${t("screens.transactions.fields.locationArea")}: ${filters.locationArea.name}`,
                onDelete: resetLocationArea,
            }] : []),
        ];
        return {
            filters,
            unsavedFilters,
            setFilters,
            validateFilters,
            applyFilters,
            resetFilters,
            defaultFilters,
            filtersChanged,
            filtersOpen,
            openFilters,
            closeFilters,
            dateRangeConfig,
            validationErrors,
            chips
        };
    }, [t, defaultFilters, filters, unsavedFilters, setFilters, validateFilters, applyFilters, resetFilters, filtersChanged, filtersOpen, openFilters, closeFilters, dateRangeConfig, validationErrors, resetPeriod, getLabelForFlag, resetFlags, resetGuid, resetLocation, resetDevice]);

    return <><TransactionFiltersContext.Provider value={context}>{children}</TransactionFiltersContext.Provider>
        <ConfirmDiscardDialog dialogOpen={dialogOpen} onClose={() => setDialogOpen(false)} onApply={() => {
            if (applyFilters()) setFiltersOpen(false);
        }}
                              onReset={() => {
                                  resetFilters();
                                  setFiltersOpen(false);
                              }}/></>;
}

export {
    TransactionFiltersContext,
    TransactionFiltersContextProvider
}
