// @todo handle errors properly
/* eslint-disable import/prefer-default-export */

export interface ErrorObject extends Error {
  errors: any;
  code: number;
}

export class UnauthorizedError extends Error {
  constructor(message: string) {
    // Required for ES5 compilation target to properly identify the instance of the error
    super(message);
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

function isValidBody(body: unknown) {
  try {
    const ob = JSON.parse(JSON.stringify(body));
    if (Object.getOwnPropertyNames(ob).length > 0) {
      return true;
    }
  } catch (e) {
    // no op
  }
  return false;
}

export function apiFetch<T>(path = '/', body: unknown = {}, method = 'GET', endpoint: string | undefined = process.env.REACT_APP_API_TRANSACTIONS, authorization: string | undefined = undefined, plain = false, extraHeaders: Record<string,string> = {}): Promise<T> {
  let options: { method: string; body?: string } = {
    method,
  };
  if (isValidBody(body)) {
    options = {...options, body: JSON.stringify(body)};
  }

  const headers: { 'Content-Type': string; Authorization?: string } = {
    'Content-Type': 'application/json',
    ...extraHeaders
  };

  if (authorization) headers.Authorization = authorization;
  const url = `${endpoint}${path}`;

  return fetch(url, {
    ...options,
    headers,
  }).then((response) => {
    if (!response.ok) {
      if (response.status === 401) {
        throw new UnauthorizedError("Received 401");
      }
      if (response.status === 403) {
        throw new UnauthorizedError("Received 403");
      }
      return response
          .json()
          .catch(() => {
            // Couldn't parse the JSON
            const errorObject: ErrorObject = {
              name: 'Error',
              message: 'Error in API call',
              errors: {
                general: {
                  key: 'errorInternalServerErrorEmpty',
                  message: 'Internal server error.',
                },
                fields: [],
              },
              code: 500,
            };

            throw errorObject;
          })
          .then((error) => {
            const errorObject: ErrorObject = {
              name: 'Error',
              message: 'Error in API call',
              errors: error.errors,
              code: error.code,
            };

            throw errorObject;
          });
    }
    if (plain) {
      return response.text() as unknown as T;
    }
    // Successful response, parse the JSON and return the data
    return response
        .json()
        .catch((err) => {
          console.error(err);
        })
        .then((respBody) => (respBody && respBody.data ? respBody.data : respBody) as T);
  });
}

/* eslint-enable import/prefer-default-export */
