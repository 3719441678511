import {Card, CardActions, CardContent, CardHeader, Divider, Grid, Typography,} from '@mui/material';
import React, {SVGProps, useContext} from 'react';
import {UIContext} from '../context/UIContext';

const InfoCard = ({
  children,
  title,
  subheader,
  Logo,
  width,
  actions,
}: {
  children?: React.ReactNode;
  title: string;
  subheader?: string;
  Logo?: React.FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  width: number;
  actions?: React.ReactNode;
}) => {
  const { colorMode } = useContext(UIContext);

  return (
    <Grid item xs={12} md={width}>
      <Card
        variant="outlined"
        sx={{
          border: (theme) =>
            `1px ${
              colorMode === 'light' ? theme?.palette?.grey?.['12p'] : theme?.palette?.grey?.['23p']
            } solid`,
        }}
      >
        <CardContent
          sx={{
            padding: 3,
            ':last-child': {
              paddingBottom: 1,
            },
            ...(!subheader && {
              paddingBottom: 1,
            }),
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={Logo ? 8 : 12}>
              <CardHeader
                title={title}
                titleTypographyProps={{ variant: 'h2' }}
                sx={{
                  padding: 0,
                  marginBottom: 2,
                }}
              />
              <Typography
                sx={{
                  color: 'text.secondary',
                }}
              >
                {subheader}
              </Typography>
            </Grid>
            {Logo && (
              <Grid item xs={4}>
                <Logo
                  style={{
                    display: 'block',
                    maxWidth: '100%',
                    marginLeft: 'auto',
                  }}
                  aria-hidden="true"
                />
              </Grid>
            )}
          </Grid>
          {children}
        </CardContent>

        {actions && (
          <>
            {subheader && <Divider />}
            <CardActions
              sx={{
                padding: 0,
              }}
            >
              {actions}
            </CardActions>
          </>
        )}
      </Card>
    </Grid>
  );
};
export default InfoCard;
