import { CSSProperties } from "react";
import { GridSortDirection } from "@mui/x-data-grid-pro";

export const ROWS_PER_PAGE_MOBILE = 50;

export const tbodyStyle: CSSProperties = {
    position: 'relative',
    display: 'block',
    minHeight: "calc(100vh - 512px)",
    maxHeight: "calc(100vh - 512px)",
};

export const denseTbodyStyle: CSSProperties = {
    position: 'relative',
    display: 'block',
    minHeight: "calc(100vh - 492px)",
    maxHeight: "calc(100vh - 492px)",
};


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator<T, Key extends keyof T>(
    order: GridSortDirection,
    orderBy: Key
): (
    a: { [key in Key]?: any },
    b: { [key in Key]?: any }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a as T, b as T, orderBy)
        : (a, b) => -descendingComparator(a as T, b as T, orderBy);
}
