import {Box, SvgIcon, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {UIContext} from '../../context/UIContext';
import {ReactComponent as WhiteLogo} from '../../img/eposnow-logo-small-white.svg';
import {ReactComponent as Logo} from '../../img/eposnow-logo-small.svg';

const LoggedOut = () => {
  const { t } = useTranslation();
  const { isMobile, colorMode } = useContext(UIContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
        ...(isMobile && {
          paddingX: 2,
        }),
      }}
    >
      <SvgIcon inheritViewBox>{colorMode === 'light' ? <Logo /> : <WhiteLogo />}</SvgIcon>
      <Typography mt={2} variant="h2">
        {t('auth.title')}
      </Typography>
      <Typography sx={{ marginTop: 2, marginBottom: 3, color: 'text.secondary' }}>
        {t('auth.description')}
      </Typography>
    </Box>
  );
};

export default LoggedOut;
