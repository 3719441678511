import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useTheme } from '@mui/material/styles';
import React, { ReactElement, useContext, useState, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertMessage } from '@eposnow/ui-core';
import { mobileRadioGroupStyle } from '../constants';
import { UIContext } from '../context/UIContext';
import { ReactComponent as WhiteSuccessIcon } from '../img/success-white.svg';
import { ReactComponent as SuccessIcon } from '../img/success.svg';
import { UserContext } from '../context/UserContext';


const desktopDialogStyle: CSSProperties = {
  marginBottom: '2vh',
  minHeight: '300px',
  minWidth: '512px',
  backgroundColor: 'background.paper',
  backgroundImage: 'none',
  overflow: 'hidden',
};

const desktopDialogContentStyle: CSSProperties = {
  textAlign: 'center',
  width: '512px',
  overflow: 'hidden'
}

const mobileDialogStyle: CSSProperties = {
  borderRadius: 0
}

const mobileDialogActionStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}

export interface ContactForm {
  topic?: string;
  message: string;
}

export interface ContactTopic {
  key: string;
  text: string;
  mustIncludeMessage?: boolean;
}

export interface SubmitResult {
  success: boolean;
  error?: string;
}

export interface ContactDialogProps {
  open: boolean;
  title: string;
  description?: string;
  topics?: ContactTopic[];
  defaultTopic?: string;
  textareaDescription?: string;
  submitForm: (payload: ContactForm) => Promise<SubmitResult>;
  onClose: () => void;
  icon?: ReactElement;
  successTitle: string;
  successMessage: string;
  canRetry?: boolean;
  fullScreen?: boolean;
}

/**
 * A contact form.
 * @param {object} props
 * @param {boolean} props.open whether the dialog is open
 * @param {string} props.title the title to show in the contact form
 * @param {string} props.description the subtitle to show in the contact form
 * @param {ContactTopic[]} props.topics the list of topics to select from (optional). If mustIncludeMessage property is set to true, disables text input until 10 characters have been typed if that topic is selected
 * @param {string} props.defaultTopic the topic key selected by default (optional)
 * @param {string} props.textareaDescription placeholder text for the message box
 * @param {(ContactForm) => Promise<SubmitResult>} props.submitForm async method that sends the form and returns an object with the result of the request
 * @param {() => void} props.onClose handler for closing the window (must set the open prop to false)
 * @param {ReactElement} props.icon Icon element to show in the contact form dialog (64px size)
 * @param {string} props.successTitle Text to show as title when the form was successfully submitted
 * @param {string} props.successMessage Text to show as subtitle when the form was successfully submitted
 * @param {boolean} props.canRetry Whether it is possible to retry submission once if it failed.
 */
const ContactDialog = (props: ContactDialogProps) => {
  const {
    open,
    icon,
    title,
    description,
    topics,
    defaultTopic,
    textareaDescription,
    submitForm,
    successTitle,
    successMessage,
    canRetry,
    onClose,
    fullScreen = false
  } = props;
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [topic, setTopic] = useState<string | undefined>(defaultTopic);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [secondTry, setSecondTry] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const theme = useTheme();
  const { colorMode, isMobile, isTablet } = useContext(UIContext);
  const { locale } = useContext(UserContext);
  const radioGroupStyle = isMobile ? mobileRadioGroupStyle : {};
  const dialogStyle = (isMobile || isTablet) ? mobileDialogStyle : desktopDialogStyle;
  const dialogContentStyle = (isMobile || isTablet) ? {} : desktopDialogContentStyle;
  const dialogActionStyle = (isMobile || isTablet) ? mobileDialogActionStyle : { marginTop: isSending ? '6px' : '8px', }
  
  const close = () => {
    setSuccess(false);
    setSecondTry(false);
    setIsSending(false);
    if (onClose) onClose();
  };
  const getButtonText = () => {
    if (isSending) {
      return t('actions.sending');
    }
    if (!errorMessage && !success) {
      return t('actions.send');
    }
    if (secondTry) {
      return t('actions.retry');
    }
    return t('actions.gotIt');
  };

  const onButtonClick = () => {
    if (isSending) {
      return;
    }
    if (success || (errorMessage && !secondTry)) {
      close();
      return;
    }
    setIsSending(true);
    setErrorMessage(undefined);
    submitForm({ topic, message })
      .then((result) => {
        setIsSending(false);
        if (result.success) {
          setSuccess(true);
        } else {
          setErrorMessage(result.error || t('export.somethingIsBroken'));
          setSecondTry((canRetry || false) && !secondTry);
        }
      })
      .catch((error) => {
        setIsSending(false);
        setErrorMessage(error || t('export.somethingIsBroken'));
        setSecondTry((canRetry || false) && !secondTry);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullScreen={fullScreen}
      PaperProps={{sx: {...dialogStyle}}} >
          <Box sx={ (isMobile || isTablet) ? {display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center'} : {}}>
        {(isMobile || isTablet) &&
            <ArrowBack
              sx={{
                margin: '16px 0 0 16px',
              }} onClick={close} />
        }
      <DialogTitle
        sx={{ 
              textAlign: 'center',
              fontWeight: 600, 
              marginTop: isMobile ? 0 : '24px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
        <>
          <Box>
            {success && (colorMode === 'light' ? <SuccessIcon /> : <WhiteSuccessIcon />)}
            {!success && icon}
          </Box>
          {success ? successTitle : title}
        </>
      </DialogTitle>

      <DialogContent
        sx={{...dialogContentStyle}}>

        {!isMobile && !isTablet &&
          <IconButton 
            sx={{ position: 'absolute', 
                  top: '16px', 
                  left: '500px',
                }} onClick={close}>
        <CloseOutlinedIcon />
      </IconButton>
      }

        <DialogContentText  
          sx={{ marginBottom: '12px', 
                whiteSpace: 'normal'
              }}>
          {success ? successMessage : description}
        </DialogContentText>

        {!success && (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={defaultTopic}
              name="radio-buttons-group"
              sx={{ marginBottom: '12px', 
                    whiteSpace: 'normal', 
                    ...radioGroupStyle,
                  }}
            >
              {topics &&
                topics.map((top) => (
                  <FormControlLabel
                    value={top.key}
                    key={top.key}
                    control={<Radio disabled={isSending || (!!errorMessage && !secondTry)} />}
                    label={top.text}
                    checked={topic === top.key}
                    onClick={() => setTopic(top.key)}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        )}
        {!success && (
          <TextField
            sx={{
              width: (isMobile || isTablet) ? '100%' : '495px',
              marginTop: topics && topics.length > 0 ? '32px' : '0px',
              marginBottom: isSending ? '6px' : '8px',
            }}
            onChange={(e) => setMessage(e.target.value)}
            multiline
            rows={3}
            placeholder={textareaDescription}
            disabled={isSending || (!!errorMessage && !secondTry)}
          />
        )}

        {isSending &&
        <LinearProgress
                        sx={{ width: '495px',
                              marginLeft: '9px',
                              ...(isMobile ? {  width: '80vw',
                                                marginLeft: '4vw',
                                              } : {})
                            }} />}
        <DialogActions sx={{...dialogActionStyle}}>
          {errorMessage && (
            <AlertMessage type="error" locale={locale} theme={theme} styles={{ height:'3rem', ...(isMobile ? {height: '16vw',} : {})}}>
              <p style={{ fontSize: '0.75rem', }}>{secondTry ? errorMessage : t('components.error.tryAgainLater')}</p>
            </AlertMessage>
          )}
          {!success && (!errorMessage || secondTry) && (
            <Button
              disabled={isSending || (!!errorMessage && !secondTry)}
              size="large"
              sx={(isMobile || isTablet) ? {flex: 1} : {marginLeft: '16px'}}
              onClick={close}
            >
              {t('actions.cancel')}
            </Button>
          )}

          <Button
            variant="contained"
            size="large"
            component="a"
            sx={(isMobile || isTablet) ? {flex: 1} : {marginLeft: '16px'}}
            disabled={
              isSending ||
              ((!topics || topics.length === 0) && message.length < 10) ||
              (topics &&
                topics.length > 0 &&
                topics.filter(
                  (it) => it.key === topic && it.mustIncludeMessage && message.length < 10
                ).length > 0)
            }
            onClick={onButtonClick}
          >
            {getButtonText()}
          </Button>
        </DialogActions>
      </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ContactDialog;
