import { Button, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { CSSProperties, ReactElement, RefObject } from 'react';
import { UIContext } from '../context/UIContext';

interface ToolButtonProps {
  iconElement: ReactElement;
  tooltip: string;
  text?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  active?: boolean;
  id?: string;
  dataQaId?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  disabled?: boolean;
}

const ToolButton = (props: ToolButtonProps) => {
  const {
    tooltip,
    iconElement,
    text,
    onClick,
    buttonStyle,
    labelStyle,
    active,
    buttonRef,
    id,
    dataQaId,
    disabled,
  } = props;

  const { colorMode, isTablet, isMobile } = React.useContext(UIContext);

  return (
    <Tooltip title={tooltip}>
      {(isMobile || isTablet) ? (
        <IconButton
          id={id}
          data-qa-id={dataQaId}
          ref={buttonRef}
          disabled={disabled}
          aria-label={text}
          aria-controls={active ? 'long-menu' : undefined}
          aria-expanded={active ? 'true' : undefined}
          aria-haspopup="true"
          sx={{
            color: 'text.secondary',
            ':hover': {
              color: 'text.primary',
              backgroundColor: colorMode === 'light' ? 'grey.4p' : 'elevation.4dp',
            },
            ...(active && {
              color: 'primary.main',
              backgroundColor: colorMode === 'light' ? 'main.8p' : 'main.26p',
            }),
            padding: 1.5,
            borderRadius: '8px',
          }}
          onClick={onClick}
        >
          {iconElement}
        </IconButton>
      ) : (
        <Button
          id={id}
          data-qa-id={dataQaId}
          ref={buttonRef}
          disabled={disabled}
          aria-label={text}
          aria-controls={active ? 'long-menu' : undefined}
          aria-expanded={active ? 'true' : undefined}
          aria-haspopup="true"
          sx={{
            color: 'text.secondary',
            ':hover': {
              color: 'text.primary',
              backgroundColor: colorMode === 'light' ? 'grey.4p' : 'elevation.4dp',
            },
            ...(active && {
              color: 'primary.main',
              backgroundColor: colorMode === 'light' ? 'main.8p' : 'main.26p',
            }),
            padding: 1.5,
            borderRadius: '8px',
          }}
          onClick={onClick}
          variant="text"
          startIcon={iconElement}
        >
          {text}
        </Button>
      )}
    </Tooltip >
  );
};

export default ToolButton;
