import {useCallback, useMemo, useState} from "react";

/*
This hook lets you control the logic of a form field with a saved value and a "temporary" value being set by the user.

It provides the following entities:
- val: T -> Returns the value that the user is setting (pending apply)
- setVal(val: T): void -> Function to call when the user changes the value in the form
- committed: T -> The value that is effectively applied
- changed: boolean -> Returns true when the value set by the user is different to the value currently applied
- apply(): void -> Take the value set by the user and set it as the applied value
- reset(): void -> Revert the changes made by the user, setting the value back to the applied value
- forceSet(val: T): void -> Set at once both the user setting and the applied value. Use when saving instant values, as
calling setVal(val) then apply() will not work since the state change will not apply inmediately.
 */
const useSavedField = <T>(value: T) => {
    const [val, setVal] = useState(value);
    const [committed, setCommitted] = useState(value);
    const changed = useMemo(() => JSON.stringify(val) !== JSON.stringify(committed), [val, committed]);
    const apply = useCallback(() => {
        setCommitted(val);
    }, [val]);
    const reset = useCallback(() => {
        setVal(committed);
    }, [committed]);
    const forceSet = useCallback((f: (prevState: T) => T) => {
        setVal(f);
        setCommitted(f);
    }, [setVal, setCommitted]);
    return {val, setVal, committed, changed, apply, reset, forceSet};
}

export default useSavedField;
