import React, { useCallback } from 'react';
import { Box, Button, Card, CardActions, CardContent, Divider, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MainHeader } from '@eposnow/ui-core';

const NotFound = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const linkNavigation = useCallback(
    (path: string | undefined) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          padding: 3,
        }}
      >
        <Box
          sx={{
            marginLeft: 0,
            marginBottom: { xs: 0, sm: 1 },
            paddingX: { xs: 2, sm: 0 },
            height: '3rem'
          }}
        >
          <MainHeader
            title={t('screens.error.notfound.title')}
            size="large"
            theme={theme}
            linkNavigationFunction={linkNavigation}
          >
            <Typography>{t('screens.error.notfound.content')}</Typography>
          </MainHeader>
        </Box>
      </CardContent>
      <Divider />
      <CardActions
        sx={{
          padding: 2,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={() => navigate(-1)}
          sx={{
            marginRight: 1,
          }}
        >
          {t('screens.error.notfound.backButton')}
        </Button>
        <Button color="primary" component={RouterLink} to="/">
          {t('screens.error.notfound.homeButton')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default NotFound;
