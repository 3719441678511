import { SM_WIDTH, MD_WIDTH, LG_WIDTH } from '@eposnow/ui-core';
import { PaletteMode, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';

export const getColorMode = (): PaletteMode =>
  (sessionStorage.getItem('colorMode') as PaletteMode) || ('light' as PaletteMode);

export const getAnimationsDisabled = (): boolean =>
  sessionStorage.getItem('animationsDisabled') === 'true';

/* eslint-disable @typescript-eslint/no-empty-function */
const UIContext = React.createContext({
  isMobile: false,
  isTablet: false,
  isSmallDevice: false,
  isLargeDevice: false,
  iOS: false,
  openSideNav: false,
  showSideNav: true,
  toggleDrawer: (_open: boolean) => {},
  isMediumDevice: true,
  colorMode: getColorMode(),
  setColorModeFromBoolean: (_newColorMode: boolean) => {},
  animationsDisabled: true,
  setAnimationsDisabledFromBoolean: (_newDisableAnimation: boolean) => {},
});
/* eslint-enable @typescript-eslint/no-empty-function */

const UIContextProvider = ({ children }: { children: JSX.Element }) => {
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const dimensionsTheme = useTheme();
  const isSmallDevice = useMediaQuery(dimensionsTheme.breakpoints.down(360));
  const isMobile = useMediaQuery(dimensionsTheme.breakpoints.down(SM_WIDTH));
  const isTablet = useMediaQuery(dimensionsTheme.breakpoints.between(SM_WIDTH, MD_WIDTH));
  const isMediumDevice = useMediaQuery(dimensionsTheme.breakpoints.up(MD_WIDTH));
  const isLargeDevice = useMediaQuery(dimensionsTheme.breakpoints.up(LG_WIDTH));
  const showSideNav = process.env.REACT_APP_SHOW_SIDEBAR === 'true';
  const [openSideNav, setOpenSideNav] = useState(
    useMediaQuery(dimensionsTheme.breakpoints.up(LG_WIDTH))
  );
  const toggleDrawer = (open: boolean) => setOpenSideNav(open);

  const [animationsDisabled, setAnimationsDisabled] = useState(getAnimationsDisabled());
  const [colorMode, setColorMode] = useState<PaletteMode>(getColorMode());

  const setColorModeFromBoolean = (isDarkTheme: boolean) => {
    const newColorMode = isDarkTheme ? 'dark' : 'light';
    sessionStorage.setItem('colorMode', newColorMode);
    setColorMode(newColorMode);
  };

  const setAnimationsDisabledFromBoolean = (isAnimationsDisabled: boolean) => {
    sessionStorage.setItem('animationsDisabled', JSON.stringify(isAnimationsDisabled));
    setAnimationsDisabled(isAnimationsDisabled);
  };

  const contextValue = {
    isMobile,
    isTablet,
    isSmallDevice,
    isLargeDevice,
    iOS,
    openSideNav,
    showSideNav,
    toggleDrawer,
    isMediumDevice,
    colorMode,
    setColorModeFromBoolean,
    animationsDisabled,
    setAnimationsDisabledFromBoolean,
  };
  return <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>;
};

export { UIContext, UIContextProvider };
