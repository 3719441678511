import React from 'react';
import {
  OverflowBehavior,
  ScrollbarsAutoHideBehavior,
  ScrollbarsVisibilityBehavior,
} from 'overlayscrollbars';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentProps,
} from 'overlayscrollbars-react';

export const defaultScrollbarOptions = {
  overflow: {
    x: 'hidden' as OverflowBehavior,
    y: 'scroll' as OverflowBehavior,
  },
  scrollbars: {
    theme: 'os-theme-dark',
    visibility: 'auto' as ScrollbarsVisibilityBehavior,
    autoHide: 'never' as ScrollbarsAutoHideBehavior,
    dragScroll: true,
    clickScroll: true,
  },
};

const WithScrollbars = (props: OverlayScrollbarsComponentProps) => {
  const { options } = props;
  return (
    <OverlayScrollbarsComponent
      element="div"
      options={{ ...defaultScrollbarOptions, ...options }}
      defer
      {...props}
    />
  );
};

export default WithScrollbars;
