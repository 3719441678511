import {Grid, Typography,} from '@mui/material';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {UserContext} from "../../../context/UserContext";


interface SalesSummaryProps {
    totalSalesItems: number;
}

// TODO sort out right margin hack
// TODO should the text be like that or split into stackable components of number and text?
const SalesSummary = (props: SalesSummaryProps) => {
    const {t} = useTranslation();
    const {totalSalesItems} = props;
    const {locale} = useContext(UserContext);
    return (
        <Grid container>
            {/*
            TODO: implement proper sales total
            <Grid item xs='auto'>
                <Typography sx={{fontWeight: 600, display: "inline-block"}}>{t('screens.common.sales')}:</Typography>
                <Typography sx={{display: "inline-block"}}>{Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'GBP'
                }).format(totalSales)}</Typography>
            </Grid>

            <Divider orientation="vertical" flexItem sx={{
                marginLeft: '1em',
                marginRight: '1em'
            }} />
            */}

            <Grid item xs='auto'>
                <Typography
                    sx={{fontWeight: 600, display: "inline-block"}}>{t('screens.common.items')}:&nbsp;</Typography>
                <Typography
                    sx={{display: "inline-block"}}>{Intl.NumberFormat(locale).format(totalSalesItems)}</Typography>
            </Grid>
        </Grid>
    )
};

export default SalesSummary;
