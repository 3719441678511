const csvrep = (field: unknown, listSeparator = ','): string => {
    if (typeof field === "string") {
        return field.split("\"").join("\\\"");
    }
    if (typeof field === "number") {
        return field.toString(10);
    }
    if (typeof field === "boolean") {
        return field ? "true" : "false";
    }
    if (Array.isArray(field)) {
        return `[${(field as Array<unknown>).map((v) => csvrep(v)).join(listSeparator)}]`;
    }
    if (!field) {
        return "";
    }
    // it's object like
    const subfields = Object.getOwnPropertyNames(field);
    return `{${subfields.map((sf) => `{${sf}: ${csvrep((field as any)[sf], listSeparator)}}`)}}`
}
const quote = (value: string, separator: string): string =>
    value.indexOf(separator) !== -1 ? `"${value}"` : value;
const objectArrayToCSV = <T>(rows: T[], separator = ';', listSeparator = ',', excludeFields: (keyof T)[] = [], transformer: (key: string, val: unknown) => unknown = (key: string, val: unknown) => val): string => {
    if (rows.length === 0) {
        return "";
    }
    const mostCompleteRow = rows.reduce((r, rMax) => Object.getOwnPropertyNames(rMax).length > Object.getOwnPropertyNames(r).length ? rMax : r);
    const fields = Object.getOwnPropertyNames(mostCompleteRow).filter((it) => !excludeFields.includes(it as unknown as keyof T));
    return `${fields.join(separator)}\n${rows.map((row) => fields.map((field) => quote(csvrep(transformer(field, row[field as keyof T]), listSeparator), separator)).join(separator)).join('\n')}`;
}

export default objectArrayToCSV;
