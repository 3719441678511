import * as React from 'react';
import { CSSProperties, ReactElement, useEffect, useRef } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box } from '@mui/material';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import DrawerTitlebar from './DrawerTitlebar';
import { defaultScrollbarOptions } from '../screens/transactions/components/WithScrollbars';

const scrollContextStyle = {
  width: 'calc(100% - 24px)',
  marginTop: 0,
  paddingRight: '12px',
  marginRight: '12px',
  marginLeft: '12px',
  marginBottom: 0,
  border: 0,
};

interface TitledDrawerProps {
  title: string;
  onClose?: () => void;
  icon: ReactElement;
  bottomToolbar?: ReactElement;
  sx?: { scrollContext?: CSSProperties; bottomToolbar?: CSSProperties };
  iconRight?: ReactElement;
}

const TitledDrawer = (
  { 
    children,
    title,
    onClose,
    icon,
    bottomToolbar, 
    sx, 
    iconRight = <CloseOutlinedIcon />
  }: React.PropsWithChildren<TitledDrawerProps>) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [initialize, getInstance] = useOverlayScrollbars({
    options: defaultScrollbarOptions,
    events: {},
  });

  useEffect(() => {
    if (scrollContainerRef && scrollContainerRef.current) {
      initialize(scrollContainerRef.current);
    }
  }, [getInstance, initialize, scrollContainerRef]);

  return (
    <Box sx={{display: 'flex', flexDirection: 'column',  height: '100%'}}>
      <DrawerTitlebar iconLeft={icon} iconRight={iconRight} title={title} onClose={onClose} />
      <Box
        sx={{
          ...scrollContextStyle,
          ...sx?.scrollContext,
        }}
        ref={scrollContainerRef}
      >
        {children}
      </Box>
      {bottomToolbar && (
        <Box sx={{marginTop: 'auto' }}>
          {bottomToolbar}
        </Box>
      )}
    </Box>
  );
};

export default TitledDrawer;
