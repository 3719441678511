
// eslint-disable-next-line import/prefer-default-export
export function setupArrayAtPolyfill() {
  // eslint-disable-next-line no-extend-native
  Array.prototype.at = function (idx) {
    const arr = Object(this);
    if (idx < -arr.length || idx >= arr.length) return undefined;
    if (idx < 0) {
      return arr[idx + arr.length];
    }
    return arr[idx];
  }
}
