import {Breadcrumb, BreadcrumbHint} from "@sentry/types/types/breadcrumb";
import {ErrorEvent, EventHint} from "@sentry/types/types/event";

export const eventHook = (event: ErrorEvent, hint: EventHint) => ({
    ...event,
});

export const breadcrumbHook = (breadcrumb: Breadcrumb, hint?: BreadcrumbHint) => ({
    ...breadcrumb,
    data: {
        ...breadcrumb.data,
        from: breadcrumb.data && breadcrumb.data.from && breadcrumb.data.from.includes("/?code=") ? "<oauth callback>" : breadcrumb.data?.from
    }
});
