import reactAxe from '@axe-core/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { LicenseInfo } from '@mui/x-license';
import App from './App';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import { breadcrumbHook, eventHook } from "./helpers/sentryHelpers";
import worker from './mocks/browser';
import reportWebVitals from './reportWebVitals';

console.info(`Reporting UI version: ${process.env.REACT_APP_RELEASE}`);

if (process.env.REACT_APP_MSW_ENABLED === 'true') {
  worker.start().then();
}

if (process.env.REACT_APP_AXE_ENABLED === 'true') {
  reactAxe(React, ReactDOM, 1000).then();
}

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    release: process.env.REACT_APP_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    autoSessionTracking: true,
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || "") || 1.0,
    beforeSend: eventHook,
    beforeBreadcrumb: breadcrumbHook,
  });
}

if (process.env.REACT_APP_GTM_ID && process.env.REACT_APP_ENVIRONMENT === 'production') {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
}

// I had to extract this config outside the App component so that it can be swapped in the integration tests
const oidcConfig = {
  scope: process.env.REACT_APP_OAUTH_SCOPE,
  authority: process.env.REACT_APP_OAUTH_HOST,
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  redirectUri: process.env.REACT_APP_OAUTH_CALLBACK_URL,
  loadUserInfo: false,
  onSignOut: () => {
    localStorage.clear();
    sessionStorage.clear();
  }
};

if (process.env.REACT_APP_MATERIAL_UI_LICENSE) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_LICENSE)
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <App oidcConfig={oidcConfig} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

