import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewWeekOutlined } from '@mui/icons-material';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, useTheme } from '@mui/material';
import { GridArrowDownwardIcon, GridArrowUpwardIcon, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import TitledDrawer from '../../../components/TitledDrawer';
import { desktopButtonStyle, desktopSecondaryButtonStyle, mobileButtonStyle, mobileSecondaryButtonStyle } from '../../../constants';
import { TransactionSummary } from '../../../models/transactions';
import { hasVisibleColumns } from '../../../helpers/helpers';
import { buildDefaultTransactionColumnVisibilityModel, reorderVisibilityModel, saveTransactionColumnsOrderPreferences, saveTransactionSelectedColumnsPreferences } from '../../../helpers/transactionHelpers';
import { UIContext } from '../../../context/UIContext';

type LocalizationMap = { [key in keyof TransactionSummary]?: string }

const localizationFieldnames: LocalizationMap = {
    date: 'screens.transactions.fields.date',
    discountsSummary: 'screens.transactions.fields.discountAmount',
    amount: 'screens.transactions.fields.amount',
    tax: 'screens.transactions.fields.tax',
    gratuity: 'screens.transactions.fields.gratuity',
    paymentMethod: 'screens.transactions.fields.paymentMethod',
    tableNumber: 'screens.transactions.fields.tableNumber',
    staff: 'screens.transactions.fields.staff',
    itemsSold: 'screens.transactions.fields.itemsSold',
    invoiceNumbers: 'screens.transactions.fields.invoiceNumbers',
    location: 'screens.transactions.fields.location',
    device: 'screens.transactions.fields.device',
    customer: 'screens.transactions.fields.customer',
    barcode: 'screens.transactions.fields.barcode',
    companyGuid: 'screens.transactions.fields.companyId',
    activeFlagAdmin: 'actions.status'
}

interface TransactionReportColumnsProps {
  initialTableColumns: GridColDef[]
  tableColumns: GridColDef[]
  setTableColumns: (c: GridColDef[]) => void;
  columnsVisibilityModel: GridColumnVisibilityModel;
  setColumnsVisibilityModel: (c: GridColumnVisibilityModel) => void;
  closeColumns: () => void;
  adminview?: boolean;
}

const TransactionReportColumnsPanel = (props: TransactionReportColumnsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { colorMode, isMobile } = React.useContext(UIContext);

  const { initialTableColumns, tableColumns, setTableColumns, columnsVisibilityModel, setColumnsVisibilityModel, closeColumns, adminview } = props;
  const [selectedColumns, setSelectedColumns] = useState<GridColumnVisibilityModel>({...columnsVisibilityModel});
  const [orderColumns, setOrderColumns] = useState<GridColDef[]>([...tableColumns]);

  const buttonStyle = isMobile ? mobileButtonStyle : desktopButtonStyle;
  const secondaryButtonStyle = isMobile ? mobileSecondaryButtonStyle : desktopSecondaryButtonStyle;

  const toggleColumns = useCallback((columnName: string) => {
    setSelectedColumns({
        ...selectedColumns,
        [columnName]: !selectedColumns[columnName]
    })
  }, [selectedColumns, setSelectedColumns]);

  const applyColumns = () => {
    setColumnsVisibilityModel(selectedColumns);
    setTableColumns(orderColumns);
    saveTransactionSelectedColumnsPreferences(selectedColumns, adminview);
    saveTransactionColumnsOrderPreferences(orderColumns, adminview);
    closeColumns();
  };
  
  const turnOffAllColumns = (columnsVisibility: GridColumnVisibilityModel): GridColumnVisibilityModel => Object.keys(columnsVisibility).reduce((columns, columnName) => ({...columns, [columnName]: false}), {})

  const resetColumns = () => {
    setOrderColumns(initialTableColumns);
    setSelectedColumns(buildDefaultTransactionColumnVisibilityModel(turnOffAllColumns(reorderVisibilityModel(initialTableColumns, selectedColumns)), adminview));
  };

  const unselectAll = () => {
    setSelectedColumns(turnOffAllColumns(selectedColumns));
  };

  const moveUp = useCallback((oldIndex: number) => {
    if(oldIndex > 0) {
      const column = orderColumns[oldIndex];
      orderColumns[oldIndex] = orderColumns[oldIndex - 1];
      orderColumns[oldIndex - 1] = column;
      setOrderColumns(orderColumns);
      setSelectedColumns(reorderVisibilityModel(orderColumns, selectedColumns));
    }
  }, [selectedColumns, setSelectedColumns, orderColumns, setOrderColumns]);

  const moveDown = useCallback((oldIndex: number) => {
    if(oldIndex < orderColumns.length - 1) {
      const column = orderColumns[oldIndex];
      orderColumns[oldIndex] = orderColumns[oldIndex + 1];
      orderColumns[oldIndex + 1] = column;
      setOrderColumns(orderColumns);
      setSelectedColumns(reorderVisibilityModel(orderColumns, selectedColumns));
    }
  }, [selectedColumns, setSelectedColumns, orderColumns, setOrderColumns]);
    

  const bottomToolbar = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%',
      }}
    >
      <Button
        variant="contained"
        sx={buttonStyle}
        onClick={() => applyColumns()}
        data-qa-id="columnApplyButton"
      >
        {t('components.filters.apply')}
      </Button>
        <Button
          variant="text"
          sx={{ ...secondaryButtonStyle, marginRight: '8px' }}
          onClick={resetColumns}
          data-qa-id="columnResetButton"
        >
          {t('components.filters.reset')}
        </Button>
    </Box>
  );

  return (
    <TitledDrawer
        title={t('components.columns')}
        onClose={() => closeColumns()}
        icon={<ViewWeekOutlined />}
        bottomToolbar={bottomToolbar}
    >
        <Grid
            item
            sx={{
                paddingLeft: '4px',
                paddingRight: '4px',
                borderBottom: 
                `1px ${
                    colorMode === 'light' ? theme?.palette?.grey?.['12p'] : theme?.palette?.grey?.['23p']
                } solid`,
            }}
        >
            <Grid item xs={8} alignItems='center'>
                <FormControlLabel
                    label={t('actions.unselectAll')}
                    control={
                        <Checkbox
                            indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
                            size="small"
                            checked={!hasVisibleColumns(selectedColumns)}
                            indeterminate={hasVisibleColumns(selectedColumns)}
                            onChange={() => unselectAll()}
                            disableRipple
                        />
                    }
                    sx={{ mr: '10px' }}
                />
            </Grid>
        </Grid>
        <Grid
            item
            sx={{
            paddingLeft: '4px',
            paddingRight: '4px',
            }}
        >
        {Object.entries(selectedColumns).map(([columnName, visible], index) => 
            (<Grid container justifyContent='flex-start'>
              <Grid item xs={9}>
                <FormControlLabel
                  label={t(localizationFieldnames[columnName as keyof TransactionSummary] as string)}
                  control={
                    <Checkbox size="small" checked={visible} onChange={() => toggleColumns(columnName)} disableRipple />
                  }
                  sx={{ mr: '10px' }}
                />
              </Grid>
              <Grid item xs={3} alignSelf='flex-end'>
                <IconButton
                  onClick={() => moveUp(index)}
                  >
                  <GridArrowUpwardIcon/>
                </IconButton>
                <IconButton
                  onClick={() => moveDown(index)}
                >
                  <GridArrowDownwardIcon/>
                </IconButton>
              </Grid>
            </Grid>))
        }
      </Grid>
    </TitledDrawer>
  );
};

export default TransactionReportColumnsPanel;
