import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from '@mui/material';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {UIContext} from '../../../context/UIContext';
import {ReactComponent as WhiteErrorIcon} from '../../../img/error-white.svg';
import {ReactComponent as ErrorIcon} from '../../../img/error.svg';

export interface ConfirmDiscardDialogProps {
  dialogOpen: boolean;
  onApply: () => void;
  onReset: () => void;
  onClose: () => void;
}

const ConfirmDiscardDialog = (props: ConfirmDiscardDialogProps) => {
  const { dialogOpen, onApply, onReset, onClose } = props;
  const { t } = useTranslation();
  const { colorMode } = useContext(UIContext);

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { padding: '24px' } }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', fontWeight: 600 }}>
        {colorMode === 'light' ? <ErrorIcon /> : <WhiteErrorIcon />}
        <br />
        {t('components.filters.confirmDiscard.title')}
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText id="alert-dialog-description">
          {t('components.filters.confirmDiscard.message')
            .split('\n')
            .map((it) => (
              <React.Fragment key={it}>
                {it}
                <br />
              </React.Fragment>
            ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          data-testid="dialog-discard"
          onClick={() => {
            onReset();
            onClose();
          }}
        >
          {t('components.filters.discard')}
        </Button>
        <Button
          size="large"
          color="primary"
          variant="contained"
          data-testid="dialog-apply"
          onClick={() => {
            onApply();
            onClose();
          }}
          autoFocus
        >
          {t('components.filters.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDiscardDialog;
