import * as Sentry from '@sentry/react';
import {useAuth} from "oidc-react";
import {useCallback, useContext, useMemo} from "react";
import {useQuery, UseQueryOptions, UseQueryResult} from "react-query";
import {useNavigate} from "react-router-dom";
import {apiFetch as apiFetchFn, UnauthorizedError} from "../api/fetch";
import {SettingsContext} from "../context/SettingsContext";
import {defer} from "./helpers";
import PageCache, {PagedResult, Pagination, WithPaging} from "./pageCache";

export interface ApiFetchParams {
    path: string;
    body: unknown;
    method?: "GET" | "PUT" | "POST" | "DELETE" | "PATCH" | "OPTIONS";
    endpoint?: string;
}

export interface ApiFetchParamsPaged<K extends WithPaging> {
    path: string;
    body: K;
    method?: "GET" | "PUT" | "POST" | "DELETE" | "PATCH" | "OPTIONS";
    endpoint?: string;
}

const validatePath = `v1/token/validate`;
const validateEndpoint = process.env.REACT_APP_API_SSO;

export type ApiQueryOptions<T> = Omit<UseQueryOptions<T, unknown, T>, "queryKey" | "queryFn">;

export type PagedApiResponse<T> = Record<string, T[]> & { pagination: Pagination };

const clearCacheAndReload = (persistSettings?: () => void) => {
    // TODO: Remove the call to persist once logging out does not depend on clearing storage
    localStorage.clear();
    sessionStorage.clear();
    if (persistSettings) persistSettings();
    window.location.reload();
};
export const getQueryFn = <T>(params: ApiFetchParams, fetchFn: (body: unknown, path: string, method: string, endpoint?: string, authorization?: string) => Promise<T>, maybeAccessToken?: string, persistSettings?: () => void, enabled = true) => async () => {
    if (!enabled) throw new Error("Query is disabled");
    const {path, body} = params;
    let {method, endpoint} = params;
    method = method || 'GET';
    endpoint = endpoint || process.env.REACT_APP_API_TRANSACTIONS;

    let result = null;
    try {
        // Very important: this JSON clone allows to delete the undefined fields from the request body
        let payload = body;
        try {
            if (payload) payload = JSON.parse(JSON.stringify(body))
        } finally { /* no op */
        }
        result = await fetchFn(payload, path, method, endpoint);
    } catch (err: unknown) {
        if (err instanceof UnauthorizedError) {
            try {
                await apiFetchFn(validatePath, false, undefined, validateEndpoint, maybeAccessToken);
                result = await fetchFn(body, path, method, endpoint);
            } catch (e: unknown) {
                if (e instanceof UnauthorizedError) {
                    clearCacheAndReload(persistSettings);
                } else {
                    Sentry.captureException(e, {
                        extra: {
                            error: JSON.stringify(e),
                            environment: process.env.REACT_APP_ENVIRONMENT,
                            params,
                            source: "getQueryFn"
                        },
                    });
                    throw e;
                }
            }
        } else {
            Sentry.captureException(err, {
                extra: {
                    error: JSON.stringify(err),
                    environment: process.env.REACT_APP_ENVIRONMENT,
                    params,
                    source: "getQueryFn"
                },
            });
            throw err;
        }
    }
    return result as T;
}
export function useApiQuery<T>(params: ApiFetchParams, queryKey: string, options?: ApiQueryOptions<T>): UseQueryResult<T> {
    const auth = useAuth();
    const maybeAccessToken = auth?.userData?.access_token;
    const {persistSettings} = useContext(SettingsContext);

    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint */
    const apiFetch = useCallback(<K extends unknown>(body: unknown = {}, path = '/', method = 'GET',
                                                     endpoint: string | undefined = process.env.REACT_APP_API_TRANSACTIONS,
                                                     authorization: string | undefined = undefined, plain = false) => apiFetchFn<K>(
        path, body, method, endpoint, authorization || `Bearer ${maybeAccessToken}`, plain), [maybeAccessToken]);

    const optionsApi = useMemo(() => ({
        ...options,
        enabled: (options?.enabled !== undefined ? options?.enabled : true) && !auth.isLoading
    }), [options, auth.isLoading]);

    return useQuery<T>([queryKey], getQueryFn<T>(params, apiFetch, maybeAccessToken, persistSettings, optionsApi.enabled), optionsApi);
}

export function useApiQueryWithPageCache<T, K extends WithPaging>(params: ApiFetchParamsPaged<K>, cache: PageCache<T>, queryKey: string, dataKey: string, options?: ApiQueryOptions<PagedResult<T>>): UseQueryResult<PagedResult<T>> {
    const auth = useAuth();
    const maybeAccessToken = auth?.userData?.access_token;
    const navigate = useNavigate();
    const {persistSettings} = useContext(SettingsContext);

    const fetchWithCache = useCallback((body: unknown, path = '/', method = 'GET',
                                        endpoint: string | undefined = process.env.REACT_APP_API_TRANSACTIONS,
                                        authorization: string | undefined = undefined
    ) => cache.fetchWithCache(async (pms: K) => {
        const {page, ...pmsWithoutPage} = pms;
        let data: PagedApiResponse<T>;
        let url = path;
        if (pms) {
            url = `${url}?${new URLSearchParams({page: (page + 1).toString(), ...pmsWithoutPage} as Record<string, string>)}`;
        }
        try {
            data = await apiFetchFn<PagedApiResponse<T>>(
                url, undefined, method, endpoint, authorization || `Bearer ${maybeAccessToken}`);
        } catch (e: any) {
            if (e && e.message.includes("403")) {
                defer(() => navigate("/no-access"));
            }
            Sentry.captureException(e, {
                extra: {
                    error: JSON.stringify(e),
                    environment: process.env.REACT_APP_ENVIRONMENT,
                    params,
                    source: "useApiQueryWithPageCache"
                },
            });
            throw new Error("Failed query");
        }
        return {
            pagination: data.pagination,
            data: JSON.parse(JSON.stringify(data[dataKey]))
        }
    }, body as K), [maybeAccessToken, cache, dataKey, params]);

    const optionsApi = useMemo(() => ({
        ...options,
        enabled: (options?.enabled !== undefined ? options?.enabled : true) && !auth.isLoading
    }), [options, auth.isLoading]);

    return useQuery<PagedResult<T>>([queryKey], getQueryFn<PagedResult<T>>(params, fetchWithCache, maybeAccessToken, persistSettings, optionsApi.enabled), optionsApi);
}
