import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, {CSSProperties, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { ArrowBack } from '@mui/icons-material';
import {UIContext} from '../../../context/UIContext';
import {ReactComponent as WhiteErrorIcon} from '../../../img/error-white.svg';

import {ReactComponent as ErrorIcon} from '../../../img/error.svg';
import {ReactComponent as WhiteHourglassIcon} from '../../../img/hourglass-white.svg';
import {ReactComponent as HourglassIcon} from '../../../img/hourglass.svg';
import {ReactComponent as WhiteSuccessIcon} from '../../../img/success-white.svg';
import {ReactComponent as SuccessIcon} from '../../../img/success.svg';

const iconProcessing =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='%23013BAE' d='M4 2A2 2 0 0 0 2 4V20A2 2 0 0 0 4 22H12.41A7 7 0 0 0 16 23A7 7 0 0 0 23 16A7 7 0 0 0 18 9.3V8L12 2H4M4 4H11V9H16A7 7 0 0 0 9 16A7 7 0 0 0 10.26 20H4V4M16 11A5 5 0 0 1 21 16A5 5 0 0 1 16 21A5 5 0 0 1 11 16A5 5 0 0 1 16 11M15 12V17L18.61 19.16L19.36 17.94L16.5 16.25V12H15Z' /%3E%3C/svg%3E";

export interface ExportDialogProps {
  open: boolean;
  progress?: number;
  fileUri?: string;
  filename?: string;
  error?: Error;
  onCancel?: () => void;
  onClose: () => void;
  onRetry: () => void;
}

const contentDesktoStyle: CSSProperties = { textAlign: 'center', width: '600px' };
const contentMobileStyle: CSSProperties = {textAlign: 'center', marginTop: '8px'};
const cardDesktopStyle: CSSProperties = {
  textAlign: 'right',
  justifyContent: 'flex-start',
  width: '340px',
  position: 'relative',
  left: 'calc(50% - 160px)',
  marginBottom: '32px',
};
const cardMobileStyle: CSSProperties = { width: '100%'}

export const ExportDialog = (props: ExportDialogProps) => {
  const { open, progress, fileUri, filename, error, onClose, onCancel, onRetry } = props;
  const { t } = useTranslation();
  const { colorMode, isMobile, isTablet } = useContext(UIContext);
  const [firstError, setFirstError] = useState(true);
  const onDialogClose = () => {
    if (!fileUri && !error) {
      /* still exporting, prevent dialog from closing */
      return;
    }
    if (onClose) onClose();
  };

  const contentStyle = (isMobile || isTablet) ? contentMobileStyle : contentDesktoStyle;
  const cardStyle = (isMobile || isTablet) ? cardMobileStyle : cardDesktopStyle;

  const onButtonClick = () => {
    if ((!fileUri && !error) || (error && !firstError)) {
      if (onCancel) onCancel();
      if (onClose) onClose();
    }
    if (fileUri) {
      // create a fake link to initiate a download
      const link = document.createElement('a');
      link.href = fileUri;
      link.setAttribute('target', '_blank');
      link.setAttribute('download', filename || 'export.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }
    if (error && firstError) {
      setFirstError(false);
      if (onRetry) onRetry();
    }
  };

  const getTitle = () => {
    if (!fileUri && !error) {
      return t('export.exportingPleaseWait');
    }
    if (fileUri) {
      return t('export.yourReportIsAvailable');
    }
    if (firstError) {
      return t('export.thereHasBeenError');
    }
    return t('export.somethingIsBroken');
  };

  const getSubtitle = () => {
    if (!fileUri && !error) {
      return t('export.canTakeAFewMinutes');
    }
    if (fileUri) {
      return t('export.itIsNowReady');
    }
    if (firstError) {
      return t('export.pleaseTryAgain');
    }
    return t('export.youMayTryAgain');
  };

    const getFileStatus = () => {
        if (fileUri) return t('export.success');
        if (error) return t('export.error');
        return 'export.processing';
    };

  const getFileIcon = () => {
      const iconSx = {width: "20px", height: "20px", marginRight: "10px"} as CSSProperties;
      if (fileUri) return colorMode === 'light' ? <SuccessIcon style={iconSx}/> :
          <WhiteSuccessIcon fontSize="20px" style={iconSx}/>;
      if (error) return colorMode === 'light' ? <ErrorIcon fontSize="20px" style={iconSx}/> :
          <WhiteErrorIcon fontSize="20px" style={iconSx}/>;
      return <img src={iconProcessing} alt={getFileStatus()} style={iconSx}/>;
  };

  const getDialogIcon = () => {
      if (fileUri) return colorMode === 'light' ? <SuccessIcon fontSize="56px"/> : <WhiteSuccessIcon fontSize="56px"/>;
      if (error) return colorMode === 'light' ? <ErrorIcon fontSize="56px"/> : <WhiteErrorIcon fontSize="56px"/>;
      return colorMode === 'light' ? <HourglassIcon fontSize="56px"/> : <WhiteHourglassIcon fontSize="56px"/>;
  };

  return (
    <Dialog open={open} onClose={onDialogClose} PaperProps={{ sx: { maxWidth: '650px', borderRadius: (isMobile || isTablet) ? 0 : '8px' } }} fullScreen={isMobile || isTablet}>
      <Box sx={ (isMobile || isTablet) ? {display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center'} : {}}>
      {(isMobile || isTablet) &&
        <ArrowBack
          sx={{margin: '16px'}}
          onClick={() => {
            if (onCancel && !fileUri && !error) onCancel();
            onClose();
          }}
        />
      }
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 600,  marginTop: (isMobile || isTablet) ? 0 :'32px'}}>
        {getDialogIcon()}
        <br />
        {t('export.exporting')} {t('export.filteredTransactions').toLowerCase()}
      </DialogTitle>
      <DialogContent sx={contentStyle}>
        {!isMobile && !isTablet &&
          <IconButton
              sx={{position: 'absolute', top: '8px', left: '600px', color: "text.secondary"}}
            onClick={() => {
              if (onCancel && !fileUri && !error) onCancel();
              onClose();
            }}
          >
          <CloseOutlinedIcon />
        </IconButton> }
        <DialogContentText sx={(isMobile || isTablet) ? { marginBottom: '24px' } : {marginBottom: '8px'}}>
          {getTitle()}
          <br />
          {getSubtitle()}
        </DialogContentText>
        <Box sx={(isMobile || isTablet) ? {marginLeft: '8px', marginRight: '8px'} : {}}>
        <Card
          variant="outlined"
          sx={cardStyle}
        >
          {' '}
          <CardContent>
            <div style={{ display: 'flex', marginBottom: '18px', textAlign: 'left', justifyContent: 'center' }}>
              {getFileIcon()}
              <Typography component="span" sx={{ fontWeight: 400, fontSize: '14px' }}>
                {filename || 'export.csv'}
              </Typography>
            </div>
            <LinearProgress
              variant={progress !== undefined ? 'determinate' : 'indeterminate'}
              style={{
                display: 'inline-block',
                width: '240px',
                marginRight: '8px',
                marginBottom: '4px',
              }}
              value={progress}
            />
            {progress !== undefined && (
              <Typography component="span" sx={{ fontSize: '14px' }}>
                {progress.toString(10)} %
              </Typography>
            )}
          </CardContent>
        </Card>
        </Box>
        <DialogActions>
          {error && (
            <Button sx={(isMobile || isTablet) ? {width: '100%'} : {}} variant="contained" size="large" component="a" onClick={onButtonClick}>
              {firstError ? t('actions.retry') : t('actions.gotIt')}
            </Button>
          )}
          {fileUri && (
            <Button sx={(isMobile || isTablet) ? {width: '100%'} : {}} variant="contained" size="large" component="a" onClick={onButtonClick}>
              {t('actions.download')}
            </Button>
          )}
          {!fileUri && !error && (
            <Button sx={(isMobile || isTablet) ? {width: '100%'} : {}} size="large" onClick={onButtonClick}>
              {!error ? t('actions.cancel') : t('actions.close')}
            </Button>
          )}
        </DialogActions>
      </DialogContent>
      </Box>
    </Dialog>
  );
};
