import {Button, Container, Typography} from '@mui/material';
import * as React from 'react';
import {CSSProperties, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {UIContext} from '../context/UIContext';
import {ReactComponent as WhiteErrorIcon} from '../img/error-white.svg';

import {ReactComponent as ErrorIcon} from '../img/error.svg';
import {ReactComponent as WhiteLoadingIcon} from '../img/loading-white.svg';
import {ReactComponent as LoadingIcon} from '../img/loading.svg';
import {ReactComponent as WhiteSearchWarningIcon} from '../img/search-warning-white.svg';
import {ReactComponent as SearchWarningIcon} from '../img/search-warning.svg';
import {ReactComponent as WhiteSuccessIcon} from '../img/success-white.svg';
import {ReactComponent as SuccessIcon} from '../img/success.svg';

export enum IconMessageIcon {
  NONE,
  SEARCH_WARNING,
  ERROR,
  SUCCESS,
  LOADING,
}

export interface IconMessageProps {
  icon?: IconMessageIcon;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  onClickButton?: () => void;
  sx?: CSSProperties;
}

function getIcon(icon: IconMessageIcon, colorMode: string): ReactElement {
  if (!icon) {
    /* eslint-disable-next-line react/jsx-no-useless-fragment */
    return <></>;
  }

  switch (icon) {
    case IconMessageIcon.SEARCH_WARNING:
      return colorMode === 'light' ? <SearchWarningIcon /> : <WhiteSearchWarningIcon />;
    case IconMessageIcon.ERROR:
      return colorMode === 'light' ? <ErrorIcon /> : <WhiteErrorIcon />;
    case IconMessageIcon.LOADING:
      return colorMode === 'light' ? <LoadingIcon /> : <WhiteLoadingIcon />;
    case IconMessageIcon.SUCCESS:
      return colorMode === 'light' ? <SuccessIcon /> : <WhiteSuccessIcon />;
    default:
      /* eslint-disable-next-line react/jsx-no-useless-fragment */
      return <></>;
  }
}

const IconMessage = (props: IconMessageProps) => {
  const { icon, title, subtitle, buttonText, onClickButton, sx } = props;
  const { t } = useTranslation();
  const { colorMode } = React.useContext(UIContext);

  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        ...sx,
      }}
    >
      {icon && getIcon(icon, colorMode)}
      {title && (
        <Typography
          sx={{
            marginBottom: '8px',
            fontFamily: 'Open Sans, sans-serif',
            fontSize: '16px',
            fontWeight: 600,
            color: 'text.primary',
          }}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          sx={{
            marginBottom: '24px',
            fontFamily: 'Open Sans, sans-serif',
            fontSize: '16px',
            color: 'text.secondary',
            textAlign: 'center',
          }}
        >
          {subtitle}
        </Typography>
      )}
      {buttonText && (
        <Button variant="contained" onClick={onClickButton}>
          {buttonText}
        </Button>
      )}
    </Container>
  );
};

export default IconMessage;
