import {apiFetch} from "./fetch";

export type User = {
  id: string;
  fullName: string;
  userName: string;
  email: string;
  location: string;
  locale: string;
  isEmailVerified: boolean;
  isTwoFactorEnabled: boolean;
};

export const getUsers = async () => apiFetch<User[]>(`/v1/users`);

