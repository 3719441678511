import React from 'react';
import { Box, Card, CardActions, CardContent, Divider, Typography, useTheme } from '@mui/material';
import { MainHeader } from '@eposnow/ui-core';

const NoPermissionsPage = ({
  title,
  description,
  actions,
}: {
  title: string;
  description: string;
  actions: JSX.Element;
}) => {
  const theme = useTheme();

  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          padding: 3,
        }}
      >
        <Box sx={{ marginBottom: { xs: 2, sm: 4 } }}>
          <MainHeader title={title} size="large" theme={theme}>
            <Typography>{description}</Typography>
          </MainHeader>
        </Box>
      </CardContent>
      <Divider />
      <CardActions
        sx={{
          padding: 2,
        }}
      >
        {actions}
      </CardActions>
    </Card>
  );
};

export default NoPermissionsPage;
