import { Box, CircularProgress } from '@mui/material';
import enLocale from 'date-fns/locale/en-GB';
import { useAuth } from 'oidc-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { WELCOME_HUB_SHOWN_COOKIE_NAME, needsRedirectWelcomeHub, AppsSection } from '@eposnow/ui-core';
import { apiFetch as apiFetchFn } from '../api/fetch';
import { useApiQuery } from '../helpers/useApi';
import localesMap from '../i18n/localesMap.json';
import { GraduationStatus, LabsFeature, SubuserRightsResponse, UserAccessRights, UserInfo, UserRole } from '../types';
import { UIContext } from './UIContext';
import AuthenticatingSpinner from '../components/AuthenticatingSpinner';
import ErrorLoadingData from '../components/ErrorLoadingData';
import { isSubLogin } from '../helpers/helpers';

const DEFAULT_LOCALE = 'en-GB';

/* eslint-enable @typescript-eslint/no-empty-function */
const UserContext = React.createContext({
  user: undefined as UserInfo | undefined,
  apiFetch: apiFetchFn,
  locale: DEFAULT_LOCALE,
  localeFns: enLocale,
  userModules: [] as UserRole[] | undefined,
  labsFeatures: [] as LabsFeature[] | undefined,
  userAccessRights: {} as UserAccessRights,
  isLoadingUserApps: false,
  appsMenu: [] as AppsSection[][],
  cdnUrl: undefined as string | undefined,
  isErrorAppsMenu: false,
  refetchAppsMenu: () => { /* no op */ }
});

export type AppsMenuResponse = {
  baseUrl: string;
  items: AppsSection[][];
}

const UserContextProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const { setColorModeFromBoolean, setAnimationsDisabledFromBoolean } = useContext(UIContext);
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const [localeFns, setLocaleFns] = useState<Locale>(enLocale);
  const [localeSet, setLocaleSet] = useState(false);
  const [cookies, setCookie] = useCookies(['useDarkTheme', 'disableAnimation', WELCOME_HUB_SHOWN_COOKIE_NAME]);
  const [cdnUrl, setCdnUrl] = useState("");
  const [appsMenu, setAppsMenu] = useState<AppsSection[][]>([]);

  const maybeAccessToken = auth?.userData?.access_token;

  // So creating a generic as <T> in this context makes the parser think this is a JSX tag instead of a generic
  // The trick is adding an extends rule but then  ESLint says that it is unnecessary to extend from unknown
  // Since we don't have a superclass holding all our entities, I have to disable this rule
  // So sorry about that.
  const apiFetch = useCallback(
    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint */
    <T extends unknown>(
      path = '/',
      body: unknown = {},
      method = 'GET',
      endpoint: string | undefined = process.env.REACT_APP_API_TRANSACTIONS,
      authorization: string | undefined = undefined,
      plain = false,
      extraHeaders: Record<string, string> = {}
    ) =>
      apiFetchFn<T>(
        path,
        body,
        method,
        endpoint,
        authorization || `Bearer ${maybeAccessToken}`,
        plain,
        extraHeaders
      ),
    [maybeAccessToken]
  );

  const importLocaleFile = async (lang: string) => {
    let localeToSet;
    try {
      localeToSet = await import(`date-fns/locale/${lang}/index.js`);
    } catch {
      localeToSet = await import(`date-fns/locale/${lang.substring(0, 2)}/index.js`);
    }
    if (!localeToSet) return Promise.reject(new Error('Locale not found'));
    setLocaleFns(localeToSet.default);
    return Promise.resolve();
  };

  const formatAccessRights = useCallback(
    (isSublogin: boolean, accessRights: SubuserRightsResponse | undefined): UserAccessRights => {
      if (!isSublogin) {
        return {
          Billing: true,
          LocationAreaID: null,
          Setup: true,
          Management: true,
          PurchaseOrderCreate: true,
          PurchaseOrderEditCancel: true,
          PurchaseOrderReceive: true,
          Product: true,
          Reporting: true,
          Margin: true,
          Till: true,
          WebIntegration: true,
          Apps: true,
          GlobalCustomer: true,
          Franchise: true,
        };
      }

      if (!accessRights) {
        return {};
      }

      return {
        Billing: accessRights.BillingRights || false,
        LocationAreaID: accessRights.AccessRights.LocationAreaID,
        Setup: accessRights.AccessRights.Setup || false,
        Management: accessRights.AccessRights.Management || false,
        PurchaseOrderCreate: accessRights.AccessRights.PurchaseOrder.Create || false,
        PurchaseOrderEditCancel: accessRights.AccessRights.PurchaseOrder.EditCancel || false,
        PurchaseOrderReceive: accessRights.AccessRights.PurchaseOrder.Receive || false,
        Product: accessRights.AccessRights.Product || false,
        Reporting: accessRights.AccessRights.Reporting || false,
        Margin: accessRights.AccessRights.Margin || false,
        Till: accessRights.AccessRights.Till || false,
        WebIntegration: accessRights.AccessRights.WebIntegration || false,
        Apps: accessRights.AccessRights.Apps || false,
        GlobalCustomer: accessRights.AccessRights.GlobalCustomer || false,
        Franchise: accessRights.AccessRights.Franchise || false,
      };
    },
    []
  );

  const {
    refetch: fetchUserData,
    data: user,
    isLoading: isLoadingUser,
    isError,
    error,
  } = useApiQuery<UserInfo>(
    {
      path: `user/info?userId=${auth?.userData?.profile.sub}`,
      body: undefined,
      endpoint: process.env.REACT_APP_API_V4,
    },
    'userInfo'
  );

  const { data: userModules, isLoading: isLoadingUserModules } = useApiQuery<UserRole[]>(
    {
      path: 'user/modules',
      body: undefined,
      endpoint: process.env.REACT_APP_API_V4,
    },
    'userModules'
  );

  const { data: labsFeatures, isLoading: isLoadingLabsFeatures } = useApiQuery<LabsFeature[]>(
    {
      path: 'Feature',
      body: undefined,
      endpoint: process.env.REACT_APP_API_V4,
    },
    'labsFeatures'
  );

  const { data: userRights, isLoading: isLoadingAccessRights } = useApiQuery<SubuserRightsResponse>(
    {
      path: `subuser/${auth?.userData?.profile.sub}`,
      body: undefined,
      endpoint: process.env.REACT_APP_API_V4,
    },
    'userRights',
    {
      enabled:
        !auth?.isLoading &&
        auth?.userData?.profile?.sub?.toLowerCase() !==
        (auth?.userData?.profile?.parent_guid as string)?.toLowerCase(),
    }
  );

  const { data: uiPreferences } = useApiQuery<{ useDarkTheme: boolean; disableAnimation: boolean }>(
    {
      path: `users/${auth?.userData?.profile.sub}/ui-preferences`,
      body: undefined,
      endpoint: process.env.REACT_APP_API_V1,
    },
    'uiPreferences',
    {
      onSuccess: (prefs) => {
        setCookie('useDarkTheme', prefs?.useDarkTheme);
        setCookie('disableAnimation', prefs?.disableAnimation);
      },
    }
  );

  const { isLoading: isLoadingUserApps, isError: isErrorAppsMenu, refetch: refetchAppsMenu } = useQuery(
    'userApps',
    () => apiFetch<AppsMenuResponse>(`v1/apps`, undefined, 'GET', process.env.REACT_APP_API_IDENTITY, undefined, false, {
      "Accept-Language": user!.UILanguageTag
    }),
    {
      enabled: !!auth?.userData?.access_token && !!user,
      onSuccess: (data: AppsMenuResponse) => {
        setCdnUrl(data.baseUrl);
        setAppsMenu(data.items);
      },
    }
  );

  const { data: graduationStatus } = useApiQuery<GraduationStatus>(
    {
      path: `organisations/${auth?.userData?.profile?.sub}/graduation-status`,
      body: undefined,
      endpoint: process.env.REACT_APP_API_V1,
    },
    'graduationStatus',
    {
      enabled:
        !auth?.isLoading &&
        auth?.userData?.profile?.sub?.toLowerCase() ===
        (auth?.userData?.profile?.parent_guid as string)?.toLowerCase(),
    }
  );

  useEffect(() => {
    setColorModeFromBoolean(uiPreferences?.useDarkTheme || cookies.useDarkTheme);
    setAnimationsDisabledFromBoolean(uiPreferences?.disableAnimation || cookies.disableAnimation);
  }, [uiPreferences]);

  useEffect(() => {
    if (auth?.userData && graduationStatus) {
      const redirectToWelcomeHub = needsRedirectWelcomeHub(
        cookies,
        setCookie,
        auth?.userData?.profile?.sub?.toLowerCase() !== (auth?.userData?.profile?.parent_guid as string)?.toLowerCase(),
        graduationStatus?.isGraduated
      );
      if (redirectToWelcomeHub) {
        window.location.replace(`${process.env.REACT_APP_LINK_ORGANISATIONS}welcome-hub`);
      }
    }
  }, [auth?.userData, cookies, setCookie, graduationStatus]);

  if (user && !localeSet) {
    let lang = user.UILanguageTag;
    const locales = localesMap.locales as Record<string, string>;
    if (!(lang in locales)) lang = 'en-GB';

    setLocale(locales[lang]);
    setLocaleSet(true);
    i18n.changeLanguage(locales[lang]).then(() => {
      importLocaleFile(locales[lang]).then();
    });
  }

  if (window.location.pathname.includes('logged-out')) return null;

  if (auth && auth.userData) {
    if (isError && (error as any)?.code === 401) return <AuthenticatingSpinner />;
    if (isError) return <ErrorLoadingData action={fetchUserData} />;
    const isLoadingUserAndPermissions =
      isLoadingUser || isLoadingUserModules || isLoadingLabsFeatures || isLoadingAccessRights;
    if (isLoadingUserAndPermissions || !user) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '96px',
          }}
        >
          <CircularProgress
            aria-label="Loading User Data"
            aria-live="polite"
            aria-busy={isLoadingUserAndPermissions || !user}
            data-testid="loading-icon"
            sx={{ color: 'text.secondary' }}
          />
        </Box>
      );
    }

    const userAccessRights = formatAccessRights(
      isSubLogin(auth?.userData?.profile?.sub, auth?.userData?.profile?.parent_guid as string),
      userRights
    );
    return (
      <UserContext.Provider
        value={{
          apiFetch,
          locale,
          localeFns,
          user,
          userModules,
          labsFeatures,
          userAccessRights,
          isLoadingUserApps,
          cdnUrl,
          appsMenu,
          isErrorAppsMenu,
          refetchAppsMenu
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }

  if (auth.isLoading) {
    return <AuthenticatingSpinner />;
  }

  return null;
};

export { UserContext, UserContextProvider };
