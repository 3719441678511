/* eslint-disable import/no-duplicates */
import {format as formatDate} from 'date-fns';
import {enGB as dateEnGb, enUS as dateEnUS, es as dateEsEs, fr as dateFrFr} from 'date-fns/locale';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import enGB from './locales/enGB.json';
import enUS from './locales/enUS.json';
import esES from './locales/esES.json';
import esMX from './locales/esMX.json';
import frFR from './locales/frFR.json';

interface Locale {
  enGB: string;
  enUS: string;
  esES: string;
  frFR: string;
}

const locales = { enGB: dateEnGb, enUS: dateEnUS, esES: dateEsEs, frFR: dateFrFr };

export const getLocalLocale = (): string => {
  if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', 'en-GB');
  }
  return localStorage.getItem('locale') as string;
};

i18n.use(initReactI18next).init({
    lng: getLocalLocale(),
    fallbackLng: 'en-GB',
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        'en-GB': enGB,
        'en-US': enUS,
        'es-ES': esES,
        'es-MX': esMX,
        'fr-FR': frFR,
    },
}).then();

i18n.services.formatter?.add('DATE_FORMAT', (value, lng) => {
  const locale = locales[lng as keyof Locale];
  return formatDate(value, 'PP', { locale });
});

i18n.services.formatter?.add('TIME_FORMAT', (value, lng) => {
  const locale = locales[lng as keyof Locale];
  return formatDate(value, 'pp', { locale });
});

export default i18n;
/* eslint-enable import/no-duplicates */
