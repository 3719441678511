import React, {useMemo} from "react";

const StorageContext = React.createContext({
    localStorage: window.localStorage,
    sessionStorage: window.sessionStorage
})

const StorageContextProvider = ({children}: { children: React.ReactNode }) => {
    const storage = useMemo(() => ({
        localStorage: window.localStorage,
        sessionStorage: window.sessionStorage
    }), []);
    return <StorageContext.Provider value={storage}>{children}</StorageContext.Provider>
}

const MockStorageContextProvider = ({mockStorage, children}: { mockStorage: Storage, children: React.ReactNode }) => {
    const storage = useMemo(() => ({
        localStorage: mockStorage,
        sessionStorage: mockStorage
    }), [mockStorage]);
    return <StorageContext.Provider value={storage}>{children}</StorageContext.Provider>
}


export {
    StorageContext, StorageContextProvider, MockStorageContextProvider
};
