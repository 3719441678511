import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Typography } from '@mui/material';
import { ReactComponent as HandLogo } from '../img/hand-with-clock.svg';
import { ReactComponent as HandLogoWhite } from '../img/hand-with-clock-white.svg';
import { UIContext } from '../context/UIContext';

const ComingSoonPage = () => {
  const { t } = useTranslation();
  const { colorMode, isMobile } = useContext(UIContext);

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        justifyContent: 'center ',
      }}
    >
      <Box
        sx={{
          width: isMobile ? '80%' : '50%',
          padding: '1.5rem',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ padding: '0.5rem' }}>
          {colorMode === 'light' ? <HandLogo /> : <HandLogoWhite />}
        </Box>
        <Box sx={{ textAlign: 'center', marginTop: '0.5em' }}>
          <Typography variant="h2" sx={{ marginBottom: '1rem' }}>
            {t('screens.comingSoon.title')}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {t('screens.comingSoon.description')}
          </Typography>
        </Box>
        <Button
          color="primary"
          variant="contained"
          sx={{ marginTop: '1.5rem' }}
          onClick={() => {
            window.location.href =
              `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CompleteTransactions.aspx` ||
              '';
          }}
        >
          {t('screens.comingSoon.classicReport')}
        </Button>
      </Box>
    </Card>
  );
};

export default ComingSoonPage;
