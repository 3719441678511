import PageCache, { PagedResult, Pagination, WithPaging } from "../helpers/pageCache";
import { ApiFetchParams, ApiQueryOptions, getQueryFn, useApiQuery, useApiQueryWithPageCache } from "../helpers/useApi";
import { TransactionAdvancedFilters } from "../models/transactions";
import { apiFetch } from "./fetch";

export type GetTransactionsRequest = {
    search?: string;
    startDate: string;
    endDate: string;
    sortBy: string;
    sortOrder: string;
    companyGuid?: string;
    hasActiveFlag?: boolean;
    activeFlagCategory?: string;
    location?: string;
    device?: string;
    locationArea?: string;
    paymentMethod?: string;
    staff?: string;
}

export type GetTransactionsDTO = GetTransactionsRequest & WithPaging;

export type TransactionsReportType = "summary" | "details";
export const REPORT_TYPE_SUMMARY = "summary";
export const REPORT_TYPE_DETAILS = "details";

export interface TransactionsApiResponse<T> {
    pagination: Pagination;
    transaction: T[];
}

export interface FlagRequest {
    category: string;
    customerComment: string;
    frontendSentryTracingId?: string;
}

export function useTransactionsPaged<T>(params: GetTransactionsDTO, cache: PageCache<T>, queryKey: string, options: ApiQueryOptions<PagedResult<T>>, isAdmin = false) {
    return useApiQueryWithPageCache<T, GetTransactionsDTO>({
        path: `/data/reporting/v1/${isAdmin ? "admin/" : ""}transactions`,
        body: params
    }, cache, queryKey, "transaction", options)
}

export function useTransactions<T>(params: GetTransactionsDTO, queryKey: string, options: ApiQueryOptions<TransactionsApiResponse<T>>, isAdmin = false) {
    return useApiQuery<TransactionsApiResponse<T>>({
        path: `/data/reporting/v1/${isAdmin ? "admin/" : ""}transactions?${new URLSearchParams(JSON.parse(JSON.stringify(params)))}`,
        body: {}
    } as ApiFetchParams, queryKey, options);
}

export function useTransactionDetails<T>(transactionId: string, queryKey: string, options: ApiQueryOptions<T>, companyIdForAdmin?: string) {
    return useApiQuery<T>({
        path: `/data/reporting/v1/${companyIdForAdmin ? `admin/` : ""}transactions/${companyIdForAdmin ? `${companyIdForAdmin}/${transactionId}` : transactionId}`,
        body: {}
    } as ApiFetchParams, queryKey, options);
}

export function flagTransaction(transactionId: string, payload: FlagRequest | undefined, accessToken: string | undefined, persistSettings: () => void): Promise<object> {
    return getQueryFn<object>({
        path: `/data/reporting/v1/transactions/${transactionId}/flag`,
        body: payload || {},
        method: 'POST'
    }, (body: unknown = {}, path = '/', method = 'GET',
        endpoint: string | undefined = process.env.REACT_APP_API_TRANSACTIONS,
        authorization: string | undefined = undefined) => apiFetch<object>(path, body, method, endpoint, authorization || `Bearer ${accessToken}`), accessToken, persistSettings)();
}

export function unflagTransaction(transactionId: string, accessToken: string | undefined, persistSettings: () => void, companyIdForAdmin?: string) {
    return getQueryFn<object>({
        path: `/data/reporting/v1/${companyIdForAdmin ? "admin/" : ""}transactions/${companyIdForAdmin ? `${companyIdForAdmin}/${transactionId}` : transactionId}/${companyIdForAdmin ? "resolve" : "unflag"}`,
        body: undefined,
        method: 'POST'
    }, (body: unknown = {}, path = '/', method = 'GET',
        endpoint: string | undefined = process.env.REACT_APP_API_TRANSACTIONS,
        authorization: string | undefined = undefined) => apiFetch<object>(path, body, method, endpoint, authorization || `Bearer ${accessToken}`), accessToken, persistSettings)();
}

export function useAdvancedFilters(queryKey: string, options: ApiQueryOptions<TransactionAdvancedFilters>, companyIdForAdmin?: string) {
    return useApiQuery<TransactionAdvancedFilters>({
        path: `/data/reporting/v1/${companyIdForAdmin ? `admin/` : ""}transactions/${companyIdForAdmin ? `${companyIdForAdmin}/` : ""}filters`,
        body: {}
    } as ApiFetchParams, queryKey, options);
}
