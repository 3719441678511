import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { UserContext } from '../context/UserContext';
import { hasAccessRights } from '../helpers/helpers';
import NoPermissionsPage from '../screens/NoPermissionsPage';
import ComingSoonPage from '../screens/ComingSoonPage';

const ReportingAccessVerification = ({ children }: { children: JSX.Element }) => {
  const { t } = useTranslation();
  const { userAccessRights } = useContext(UserContext);

  return (
    <>
      {!hasAccessRights(userAccessRights, 'Reporting') && (
        <NoPermissionsPage
          title={t('errors.accessRestricted')}
          description={t('errors.sorryYouNeedPermission')}
          actions={
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => {
                window.location.href = process.env.REACT_APP_LINK_BACK_OFFICE || '';
              }}
            >
              {t('actions.goToBackOffice')}
            </Button>
          }
        />
      )}
      {hasAccessRights(userAccessRights, 'Reporting') &&
        !hasAccessRights(userAccessRights, 'LocationAreaID', null) && <ComingSoonPage />}
      {hasAccessRights(userAccessRights, 'Reporting') &&
        hasAccessRights(userAccessRights, 'LocationAreaID', null) &&
        children}
    </>
  );
};

export default ReportingAccessVerification;
