import {Box, Card, CardContent, Typography} from '@mui/material';
import React from 'react';
import {useQuery} from 'react-query';
import {getUsers, User} from '../../api/users';

/**
 * Example of how to pass data down to child components.
 * In theory, only the child component (here it's UserList) will re-render when users updates, meaning less re-rendering.
 */
const UserExample = ({user}: { user?: User }) => {
    if (!user) return <Typography>Loading</Typography>;

    return (
        <Card sx={{margin: 1}}>
      <CardContent>
        <Typography variant="h5">
            {user.fullName}
        </Typography>
        <Typography>{user.email}</Typography>
        <Typography>{user.userName}</Typography>
        <Typography>
          {user.locale} /{user.location}
        </Typography>
      </CardContent>
    </Card>
  );
};

/**
 * Example of how to pass data down to child components.
 * In theory, only the child component (here it's UserList) will re-render when users updates, meaning less re-rendering.
 * If components these are re-useable on other pages, or if the page gets long, feel free to move them to /components/.
 */
const UserList = ({ users, isLoading }: { users: User[] | undefined; isLoading: boolean }) => {
  if (!users || isLoading) return <Typography>Loading</Typography>; // A nice loader / skeleton would be nice in instances like this, for now loading is fine.

  return (
    <>
      {users.map((user) => (
        <UserExample user={user} key={user.email} />
      ))}
    </>
  );
};

const Debug = () => {
  const { data: users, isLoading } = useQuery<User[], Error>('users', getUsers);

  return (
    <Box>
      {/* Config Example */}
      <Typography variant="h4">Loaded config</Typography>
      <Typography>
        API Identity:
        {process.env.REACT_APP_API_IDENTITY}
      </Typography>

      {/* Lifted State Example */}
      <Typography variant="h4">All Users</Typography>

      <UserList users={users} isLoading={isLoading} />

      <Typography variant="h4">Single User</Typography>
      <UserExample user={users ? users[0] : undefined} />
    </Box>
  );
};

export default Debug;
