import CloseIcon from '@mui/icons-material/Close';
import {Alert, AlertColor, Button, Snackbar} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import React from "react";


export interface EnhancedSnackbarProps {
    open: boolean;
    autoHideDuration?: number;
    severity: AlertColor;
    message: string;
    action?: string;
    onAction?: () => void;
    onClose?: () => void;
}

const ActionButton = ({
                          onAction,
                          action,
                          onClose,
                      }: {
    onAction?: () => void;
    action?: string;
    onClose?: () => void;
}) => (
    <>
        <Button color="secondary" size="small" onClick={onAction}>
            {action}
        </Button>
        <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small"/>
        </IconButton>
    </>
);

const EnhancedSnackbar = (props: EnhancedSnackbarProps) => {
    const {open, autoHideDuration, severity, message, action, onAction, onClose} = props;

    return <Snackbar open={open} autoHideDuration={autoHideDuration || 6000} onClose={onClose}
                     action={action && <ActionButton action={action} onClose={onClose} onAction={onAction}/>}>
        <Alert variant="filled" onClose={onClose} color={severity} severity={severity} sx={{width: '100%'}}>
            {message}
        </Alert>
    </Snackbar>
}

export default EnhancedSnackbar;
