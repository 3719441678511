import {Alert} from "@mui/material";
import React, {ReactElement, useContext} from "react";
import {useTranslation} from "react-i18next";
import {UserContext} from "../context/UserContext";

const AdminGuard = (props: { children: ReactElement }) => {
    const {children} = props;
    const {userModules} = useContext(UserContext);
    const {t} = useTranslation();
    if (userModules && userModules.filter((module) => module.RoleName === "_EposNowReportingAdmin").length > 0) {
        return children;
    }
    return <Alert severity="error">{t("errors.forbidden")}</Alert>;
}

export default AdminGuard;
