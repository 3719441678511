import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {StorageContext} from "./StorageContext";

const defaultSettings: Record<string, string> = {
    daysStart: "5",
    weeksStart: "monday",
    view: "hospitality",
    densePadding: "false",
    rowsPerPage: "10",
};

const SettingsContext = React.createContext({
    settings: defaultSettings,
    setSetting: (key: string, value: string) => { /* no op */
    },
    persistSettings: () => { /* no op */
    },
});

const SettingsContextProvider = ({children}: { children: React.ReactNode }) => {
    const {localStorage} = useContext(StorageContext);
    const [settings, setSettings] = useState(defaultSettings);

    useEffect(() => setSettings((prevSettings) => {
        const storedSettings: any = {...prevSettings};
        Object.getOwnPropertyNames(prevSettings).forEach((key) => {
            storedSettings[key] = localStorage.getItem(`settings.${key}`) || storedSettings[key];
        });
        return storedSettings;
    }), [localStorage]);

    const setSetting = useCallback((key: string, value: string) => setSettings((prevSettings) => {
        const storedSettings: any = {...prevSettings};
        storedSettings[key] = value;
        localStorage.setItem(`settings.${key}`, value);
        return storedSettings;
    }), [localStorage]);

    const persistSettings = useCallback(() => {
        Object.getOwnPropertyNames(settings).forEach((key) => {
            localStorage.setItem(`settings.${key}`, settings[key]);
        });
    }, [settings]);

    const context = useMemo(() => ({settings, setSetting, persistSettings}), [settings, setSetting, persistSettings]);
    return <SettingsContext.Provider value={context}>{children}</SettingsContext.Provider>;
}

export {
    SettingsContext,
    SettingsContextProvider
};
