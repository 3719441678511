import React, { useCallback, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { MainHeader } from '@eposnow/ui-core';
import InfoCard from '../../components/InfoCard';
import { UserContext } from '../../context/UserContext';
import BasicTable from '../insights/components/ReportTable';
import SalesSummary from '../transactions/components/SalesSummary';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useContext(UserContext);

  const linkNavigation = useCallback(
    (path: string | undefined) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  return (
    <>
      <Box
        sx={{
          marginLeft: 0,
          marginBottom: { xs: 0, sm: 1 },
          paddingX: { xs: 2, sm: 0 },
          height: '3rem'
        }}
      >
        <MainHeader
          title={t('screens.home.title', { name: `${user?.FirstName} ${user?.LastName}` })}
          theme={theme}
          linkNavigationFunction={linkNavigation}
        >
          <Typography> {t('screens.home.content')}</Typography>
        </MainHeader>
      </Box>

      <Grid container spacing={3}>
        <InfoCard
          title={t('screens.home.boxes.salesOverview.title')}
          width={6}
          actions={
            <Button
              component={RouterLink}
              to="/transactions"
              sx={{
                paddingRight: 2,
                paddingLeft: 2,
              }}
            >
              {t('screens.home.boxes.salesOverview.buttonText')}
            </Button>
          }
        >
          {/* todo: update with real count fetching from API */}
          <SalesSummary totalSalesItems={0} />
        </InfoCard>

        <InfoCard
          title={t('screens.home.boxes.topProducts.title')}
          width={6}
          actions={
            <Button
              component={RouterLink}
              to="/insights/callouts"
              sx={{
                paddingRight: 2,
                paddingLeft: 2,
              }}
            >
              {t('screens.home.boxes.topProducts.buttonText')}
            </Button>
          }
        >
          <BasicTable />
        </InfoCard>

        <InfoCard
          title={t('screens.home.boxes.screamers.title')}
          subheader={t('screens.home.boxes.screamers.content')}
          width={6}
          actions={
            <Button
              component={RouterLink}
              to="/insights/callouts"
              sx={{
                paddingRight: 2,
                paddingLeft: 2,
              }}
            >
              {t('screens.home.boxes.screamers.buttonText')}
            </Button>
          }
        >
          <WarningAmberIcon />
        </InfoCard>
      </Grid>
    </>
  );
};

export default Home;
