import React, { ReactElement, useContext } from 'react';
import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlertMessage } from '@eposnow/ui-core';
import { UIContext } from '../context/UIContext';
import { UserContext } from '../context/UserContext';

export interface AlertDialogProps {
  open: boolean;
  confirmAction: () => void;
  cancelAction?: () => void;
  title: string;
  description: string;
  confirmButtonText: string;
  cancelActionButtonText?: string;
  confirmingButtonText?: string;
  isUpdating?: boolean;
  banner?: string;
  bannerType?: AlertColor;
  icon?: ReactElement;
  thirdActionButtonText?: string;
  thirdAction?: () => void;
  textAlign?: 'left' | 'center';
  paperSx?: any;
  actionsSx?: any;
}

const AlertDialog = ({
  open,
  confirmAction,
  cancelAction,
  title,
  description,
  confirmButtonText,
  confirmingButtonText,
  isUpdating,
  banner,
  bannerType,
  icon,
  thirdActionButtonText,
  thirdAction,
  textAlign,
  paperSx,
  actionsSx,
  cancelActionButtonText,
}: AlertDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useContext(UIContext);
  const { locale } = useContext(UserContext);

  return (
    <Dialog
      open={open}
      onClose={cancelAction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: paperSx }}
    >
      <DialogTitle
        id="alert-dialog-title"
        component="h3"
        variant="h3"
        sx={textAlign === 'center' ? { textAlign: 'center' } : {}}
      >
        {icon}
        {icon && <br />}
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={textAlign === 'center' ? { textAlign: 'center' } : {}}
        >
          {description}
        </DialogContentText>
        {banner && (
          <AlertMessage
            type={bannerType || 'info'}
            styles={{ marginTop: '24px' }}
            locale={locale}
            theme={theme}
          >
            {banner}
          </AlertMessage>
        )}
      </DialogContent>
      <DialogActions sx={{ ...actionsSx, ...(isMobile ? { flexDirection: 'column' } : {}) }}>
        {thirdAction && (
          <>
            <Button
              sx={{ padding: '12px 24px', textTransform: 'none' }}
              color="primary"
              variant="outlined"
              onClick={thirdAction}
            >
              {thirdActionButtonText}
            </Button>
            <Box flexGrow={1} />
          </>
        )}
        {cancelAction && (
          <Button sx={{ padding: '12px 24px', textTransform: 'none' }} onClick={cancelAction}>
            {cancelActionButtonText || t('actions.cancel')}
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          disableElevation
          sx={{ padding: '12px 24px', textTransform: 'none' }}
          onClick={() => !isUpdating && confirmAction()}
          autoFocus
        >
          {isUpdating && (
            <>
              <CircularProgress color="inherit" size="20px" sx={{ marginRight: '8px' }} />
              {confirmingButtonText}
            </>
          )}
          {!isUpdating && confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
