import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {Box, Button, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

const ErrorLoadingData = ({ action }: { action: () => void }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '48px' }}>
      <ErrorOutlineOutlinedIcon sx={{ fontSize: 96, marginBottom: '8px' }} color="error" />
      <Typography variant="h4">{t('components.error.failedToLoad')}</Typography>
      <Typography sx={{ marginTop: 2, marginBottom: 4, color: 'text.secondary' }}>
        {t('components.error.failedMessage')}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        type="button"
        disableElevation
        sx={{ padding: '12px 24px', textTransform: 'none' }}
        onClick={() => action()}
      >
        {t('components.error.retry')}
      </Button>
    </Box>
  );
};

export default ErrorLoadingData;
