export interface Pagination {
    limit: number;
    page: number;
    totalPages: number;
    totalRecords: number;
}

export interface PagedResult<T> {
    pagination: Pagination;

    data: T[];
}

export interface WithPaging {
    page: number;
    limit: number;
}

// Method for cleaning up the cache to prevent excessive memory usage.
export enum CacheType {
    // cache is not cleared, therefore, all records are stored in memory until page is refreshed
    PERSISTENT = 0,
    // cache is cleared when parameters of the request other than page are changed
    RESET_ON_FILTER_CHANGE = 1,
    // cache is cleared each time an API request is made (i.e. every cacheFactor pages)
    RESET_ON_API_FETCH = 2,
    // disable caching
    NO_CACHE = 3
}

export default class PageCache<T> {
    private pages: Record<string, Record<number, PagedResult<T>>> = {};

    private readonly cacheFactor: number;

    private cacheType: CacheType;

    constructor(cacheFactor = 5, cacheType = CacheType.RESET_ON_FILTER_CHANGE) {
        this.cacheFactor = cacheFactor;
        this.cacheType = cacheType;
    }

    setCacheType(cacheType: CacheType) {
        this.cacheType = cacheType;
    }

    clear() {
        this.pages = {};
    }

    private async fetchPage<K extends WithPaging>(fetchMethod: (params: K) => Promise<PagedResult<T>>, params: K): Promise<PagedResult<T>> {
        const {page, ...pms} = {...params};
        const key = JSON.stringify(pms);
        if (!(key in this.pages)) {
            if (this.cacheType === CacheType.RESET_ON_FILTER_CHANGE) {
                this.pages = {};
            }
            this.pages[key] = {};
        }
        if (!(params.page in this.pages[key])) {
            if (this.cacheType === CacheType.RESET_ON_API_FETCH) {
                this.pages = {};
                this.pages[key] = {};
            }
            try {
                this.pages[key][page] = await fetchMethod({
                    ...params,
                });
            } catch (e) {
                delete this.pages[key][page];
                throw e;
            }
        }
        return this.pages[key][page]
    }


    async fetchWithCache<K extends WithPaging>(fetchMethod: (params: K) => Promise<PagedResult<T>>, params: K): Promise<PagedResult<T>> {
        if (this.cacheType === CacheType.NO_CACHE) {
            return fetchMethod({...params});
        }
        const apiPage = Math.floor(params.page / this.cacheFactor)
        const apiReq = await this.fetchPage(fetchMethod, {
            ...params,
            page: apiPage,
            limit: params.limit * this.cacheFactor
        } as K);
        const startAt = (params.page % this.cacheFactor) * params.limit;
        return {
            pagination: {
                limit: params.limit,
                page: params.page,
                totalPages: apiReq.pagination.totalPages / this.cacheFactor,
                totalRecords: apiReq.pagination.totalRecords
            },
            data: apiReq.data.slice(startAt, startAt + params.limit)
        }
    }

}
