import { TableBody } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useTranslation } from "react-i18next";
import IconMessage, { IconMessageIcon } from "../../../components/IconMessage";
import { denseTbodyStyle, tbodyStyle } from "../constants";

const Loading = (props: { dense: boolean }) => {
    const { t } = useTranslation();
    const { dense } = props;
    return <TableBody
        style={dense ? denseTbodyStyle : tbodyStyle}>
        <TableRow style={dense ? denseTbodyStyle : tbodyStyle}>
            <TableCell colSpan={20} padding="none"
                style={{
                    height: dense ? 'calc(100vh - 492px)' : 'calc(100vh - 512px)',
                    borderBottom: "none",
                    width: "100vw"
                }}>
                <IconMessage
                    title={t("components.iconMessage.loading.title")}
                    icon={IconMessageIcon.LOADING}
                    subtitle={t("components.iconMessage.loading.subtitle")} />
            </TableCell>
        </TableRow>
    </TableBody>
};

export default Loading;
