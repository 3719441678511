import React, { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { LinkInterface, SideNav, TopNav } from '@eposnow/ui-core';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { UIContext } from '../context/UIContext';
import { UserContext } from '../context/UserContext';
import {
  deepClone,
  getReferralLink,
  hasAccessRights,
  hasAtLeastOneModule,
  hasFeature,
  hasModule,
} from '../helpers/helpers';
import { SettingsContext } from '../context/SettingsContext';
import { ReactComponent as CalculatorIcon } from '../img/Calculator.svg';
import useCountries from '../helpers/useCountries';

/**
 * Generates a PKCE flow code verifier: A high-entropy cryptographic random STRING using the unreserved
 * characters [A-Z] / [a-z] / [0-9] / "-" / "." / "*" / "~" from Section 2.3 of [RFC3986],
 * with a minimum length of 43 characters and a maximum length of 128 characters.
 * @returns code verifier
 */
const generateVerifier = () => {
  const toCharCodes = (arr: Uint8Array) => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return arr.map((x) => validChars.charCodeAt(x % validChars.length));
  };

  const randomStr = (len: number) => {
    const arr = new Uint8Array(len);
    window.crypto.getRandomValues(arr);
    return String.fromCharCode(...Array.from(toCharCodes(arr)));
  };

  return randomStr(43);
};

const NavigationComponents = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { persistSettings } = useContext(SettingsContext);
  const {
    isMobile,
    openSideNav,
    toggleDrawer,
    iOS,
    isMediumDevice,
    animationsDisabled,
    showSideNav,
  } = useContext(UIContext);
  const { user, locale, apiFetch, appsMenu, cdnUrl, userModules, labsFeatures, userAccessRights, isErrorAppsMenu, refetchAppsMenu } = useContext(UserContext);

  const linkNavigation = useCallback(
    (path: string, absoluteUrl?: boolean) => {
      if (absoluteUrl) {
        window.location.href = path;
      } else {
        navigate(path);
      }
    },
    [navigate]
  );

  const logout = () => {
    const idToken = deepClone(auth.userData?.id_token);

    apiFetch(`v1/session`, {}, 'DELETE', process.env.REACT_APP_API_SSO).finally(() => {
      auth.signOut().then(() => {
        // TODO: Remove the call to persist once logging out does not depend on clearing storage
        persistSettings();
        const queryParams = new URLSearchParams({
          id_token_hint: idToken,
          post_logout_redirect_uri: `${process.env.REACT_APP_OAUTH_POST_LOGOUT_REDIRECT_URI}?ReturnUrl=${window.location.origin}/logged-out`,
          client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
          state: generateVerifier(),
        } as Record<string, string>);
        window.location.href = `${process.env.REACT_APP_OAUTH_HOST
          }/oauth2/sessions/logout?${queryParams.toString()}`;
      });
    });
  };

  const accountUrl = auth?.userData?.profile?.is_user_migrated
    ? process.env.REACT_APP_LINK_MANAGE_ACCOUNT || ''
    : process.env.REACT_APP_LINK_PROFILE_BACK_OFFICE || '';
  const links: LinkInterface[] = [
    ...(hasAccessRights(userAccessRights, 'Reporting') ? [
      ...(process.env.REACT_APP_SHOW_HOME_IN_SIDENAV === 'true'
        ? [{ text: t('nav.home'), path: '/', icon: <HomeOutlinedIcon />, level: 1 }]
        : []),
      {
        text: t('nav.dashboard'),
        path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/Dashboard.aspx`,
        absoluteUrl: true,
        icon: <ViewQuiltOutlinedIcon />,
        level: 1,
        dataQaId: "navDashboard",
      },
      {
        text: t('nav.transactionsReports'),
        path: '/transactions',
        icon: <SummarizeOutlinedIcon />,
        level: 1,
        dataQaId: "navTransactionReports",
        children: [
          {
            text: t('nav.transactions'),
            path: '/transactions',
            level: 2,
            chip: <Chip size="small" label="New" color="primary" sx={{ marginLeft: '8px', fontWeight: 'initial' }} />,
            dataQaId: "navCompletedTransactionReports",
          },
          {
            text: t('nav.heldTransactions'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/HeldTransactions.aspx`,
            absoluteUrl: true,
          },
          ...(hasModule(userModules, 'ModuleTable') || hasModule(userModules, 'ModuleWebIntegration')
            ? [
              {
                text: t('nav.orderedTransactions'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/OrderedTransactions.aspx`,
                absoluteUrl: true,
              },
            ]
            : []),
          ...(hasModule(userModules, 'ModuleTip')
            ? [
              {
                text: t('nav.paidTransactions'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/PaidTransactions.aspx`,
                absoluteUrl: true,
              },
            ]
            : []),
          ...(hasModule(userModules, 'ModuleTable') || hasModule(userModules, 'ModuleWebIntegration')
            ? [
              {
                text: t('nav.orderedProducts'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/OrderedProducts.aspx`,
                absoluteUrl: true,
              },
            ]
            : []),
          ...(hasModule(userModules, 'ModulePrepStatus')
            ? [
              {
                text: t('nav.orderDisplay'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/PreparationStatusReport.aspx`,
                absoluteUrl: true,
              },
            ]
            : []),
          ...(hasModule(userModules, 'ModuleTableStatus')
            ? [
              {
                text: t('nav.orderServiceStage'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/OrderServiceStage.aspx`,
                absoluteUrl: true,
              },
            ]
            : []),
        ]
      },
      ...(hasModule(userModules, '_EposNowReportingAdmin')
        ? [
          {
            text: t('nav.transactionsAdmin'),
            path: '/admin/transactions',
            icon: <AdminPanelSettingsOutlinedIcon />,
            level: 1,
          },
        ]
        : []),
      ...(process.env.REACT_APP_SHOW_CALLOUTS_IN_SIDENAV === 'true'
        ? [
          {
            text: t('nav.insights'),
            path: '/insights/callouts',
            icon: <InsightsIcon />,
            level: 1,
            children: [
              {
                text: t('nav.callouts'),
                path: '/insights/callouts',
                level: 2,
              },
            ],
          },
        ]
        : []),
      {
        text: t('nav.salesReport'),
        level: 1,
        path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SalesBreakdown.aspx`,
        absoluteUrl: true,
        icon: <BarChartOutlinedIcon />,
        children: [
          {
            text: t('nav.salesReportTimeIntervals'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SalesBreakdown.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.salesReportTimePeriod'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SalesBreakdownByTime.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.salesReportTimeComparisons'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/TimeComparisons.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.salesReportSalesByProduct'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/DailySales.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.salesReportEmployees'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SalesByEmployee.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.salesReportLocations'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SalesByLocation.aspx`,
            absoluteUrl: true,
          },
          ...(hasModule(userModules, 'ModuleCorporate')
            ? [
              {
                text: t('nav.salesReportDeviceGroups'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SalesByDeviceGroup.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          {
            text: t('nav.salesReportSize'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SalesBySize.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.salesReportMiscProducts'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/MiscSales.aspx`,
            absoluteUrl: true,
          },
          ...(hasModule(userModules, 'ModuleTable')
            ? [
              {
                text: t('nav.salesReportWetAndDry'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/WetAndDry.aspx`,
                absoluteUrl: true,
              },
              {
                text: t('nav.salesReportCovers'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/Covers.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          {
            text: t('nav.salesReportPromotions'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/PromotionReport.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.salesReportBrands'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/TurnoverByBrand.aspx`,
            absoluteUrl: true,
          },
          ...(hasModule(userModules, 'ModuleSupplierIntegration') || hasModule(userModules, 'ModuleRetail')
            ? [
              {
                text: t('nav.salesReportSuppliers'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/TurnoverBySupplier.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          {
            text: t('nav.salesReportCustomerTypes'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SalesByCustomerType.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.salesReportReportingCategories'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/TurnoverByReportCategory.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.salesReportTillCategories'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/TurnoverByCategory.aspx`,
            absoluteUrl: true,
          },
          ...(hasModule(userModules, 'ModuleDelivery')
            ? [
              {
                text: t('nav.salesReportEatInEatOut'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/EatInEatOut.aspx`,
                absoluteUrl: true,
              },
              {
                text: t('nav.salesReportDiningOptions'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/PurchaseType.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          {
            text: t('nav.salesReportMultipleChoiceProducts'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/MultipleChoiceProducts.aspx`,
            absoluteUrl: true,
          },
        ]
      },
      {
        text: t('nav.stock'),
        level: 1,
        path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/StockReport.aspx`,
        absoluteUrl: true,
        icon: <InventoryOutlinedIcon />,
        children: [
          {
            text: t('nav.stockLevels'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/StockReport.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.stockWarnings'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/StockWarning.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.stockDiscrepancies'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/StockMoveDiscrepancy.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.stockNonSelling'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/NonsellingProducts.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.stockHistory'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/StockHistory.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.stockChanges'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/StockChanges.aspx`,
            absoluteUrl: true,
          },
          ...(hasAccessRights(userAccessRights, 'Margin') && hasModule(userModules, 'ModuleMarginReport')
            ? [
              {
                text: t('nav.stockTheoreticalMargin'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/TheoreticalMargin.aspx`,
                absoluteUrl: true,
              },
            ] : []),
        ]
      },
      {
        text: t('nav.customer'),
        level: 1,
        path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CustomerReport.aspx`,
        absoluteUrl: true,
        icon: <HailOutlinedIcon />,
        children: [
          {
            text: t('nav.customerReport'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CustomerReport.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.customerCredit'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CustomerCredits.aspx`,
            absoluteUrl: true,
          },
          ...(hasModule(userModules, 'ModuleLoyalty')
            ? [
              {
                text: t('nav.customerSignups'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CustomerSignups.aspx`,
                absoluteUrl: true,
              },
              {
                text: t('nav.customerSatisfaction'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CustomerSatisfaction.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          ...(hasModule(userModules, 'ModuleTreatPointsAsTender')
            ? [
              {
                text: t('nav.customerPointsSpent'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CustomerPointsSpent.aspx`,
                absoluteUrl: true,
              },
              {
                text: t('nav.customerPointsSpentOnProducts'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CustomerPointsSpentOnProduct.aspx`,
                absoluteUrl: true,
              },
            ] : []),
        ]
      },
      ...(hasModule(userModules, '_EposNowBalancePlatform')||
      hasModule(userModules, '_EposNowPayments')) ?
        [
          {
            text: t('nav.payments'),
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}hq/reports/paymentreporting`,
            icon: <PaymentsOutlinedIcon />,
            level: 1,
            dataQaId: 'navPayments',
            children: [
              {
                text: t('nav.bankingPaymentsPayouts'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}hq/reports/paymentreporting`,
                absoluteUrl: true,
              },
            ]
          }
      ] : [],
      {
        text: t('nav.banking'),
        level: 1,
        path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/EODReport.aspx`,
        absoluteUrl: true,
        icon: <AccountBalanceOutlinedIcon />,
        children: [
          {
            text: t('nav.bankingTenders'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SalesByTender.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.bankingEndOfDay'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/EODReport.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.bankingFloatAdjustments'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/FloatAdjustReport.aspx`,
            absoluteUrl: true,
          },
          ...(hasModule(userModules, 'ModuleNewsagent')
            ? [
              {
                text: t('nav.bankingPayouts'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/PayOutReasons.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          {
            text: t('nav.bankingPettyCash'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/PettyCashReport.aspx`,
            absoluteUrl: true,
          },
          ...(hasModule(userModules, 'ModuleTable')
            ? [
              {
                text: t('nav.bankingServiceCharges'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/ServiceCharges.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          ...(hasModule(userModules, 'ModuleGratuity')
            ? [
              {
                text: t('nav.bankingGratuity'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/GratuityReport.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          ...(hasModule(userModules, 'ModuleTip')
            ? [
              {
                text: t('nav.bankingTipOut'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/TipOutReport.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          ...(hasFeature(labsFeatures, 'Cashback')
            ? [
              {
                text: t('nav.bankingCashback'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/Cashback.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          {
            text: t('nav.bankingIntegratedCardTypes'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}hq/reports/tenderdetails`,
            absoluteUrl: true,
          },
        ]
      },
      {
        text: t('nav.accounting'),
        level: 1,
        path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SageReport.aspx`,
        absoluteUrl: true,
        icon: <CalculatorIcon />,
        children: [
          {
            text: t('nav.accountingBookkeeping'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/SageReport.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.accountingDailyTax'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/VATSummary.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.accountingMonthlyTax'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/MonthlyTax.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.accountingQuarterlyTax'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/QuarterlyVAT.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.accountingEndOfYearTax'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/EndOfYearTax.aspx`,
            absoluteUrl: true,
          },
          ...(hasModule(userModules, 'ModulePayroll')
            ? [
              {
                text: t('nav.accountingPayroll'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/StaffPayroll.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          ...(hasAccessRights(userAccessRights, 'Margin') && hasModule(userModules, 'ModuleCorporate')
            ? [
              {
                text: t('nav.accountingGrossProfit'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/ProfitAndLoss.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          ...(hasModule(userModules, 'ModuleWaiterBanking')
            ? [
              {
                text: t('nav.accountingServerDepositReport'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}serverdepositreport`,
                absoluteUrl: true,
              },
            ] : []),
          ...(hasModule(userModules, 'ModuleLabourReport')
            ? [
              {
                text: t('nav.accountingLabourReport'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}labourreport`,
                absoluteUrl: true,
              },
            ] : []),
          ...(hasFeature(labsFeatures, 'MonthEndReport')
            ? [
              {
                text: t('nav.accountingMonthEndReport'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/MonthEndReport.aspx`,
                absoluteUrl: true,
              },
            ] : []),
          ...(hasFeature(labsFeatures, 'BottleDeposit')
            ? [
              {
                text: t('nav.accountingContainerFeeReport'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}containerfeereport`,
                absoluteUrl: true,
              },
            ] : []),
        ]
      },
      {
        text: t('nav.auditing'),
        level: 1,
        path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/Refunds.aspx`,
        absoluteUrl: true,
        icon: <FactCheckOutlinedIcon />,
        children: [
          {
            text: t('nav.auditingRefunds'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/Refunds.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.auditingDiscounts'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/DiscountReport.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.auditingNoSales'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/NoSaleReasons.aspx`,
            absoluteUrl: true,
          },
          {
            text: t('nav.auditingVoidDeleteLines'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/VoidLines.aspx`,
            absoluteUrl: true,
          },
          ...(hasFeature(labsFeatures, 'AgeVerification')
            ? [
              {
                text: t('nav.auditingAgeVerification'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}hq/reports/ageverification`,
                absoluteUrl: true,
              },
            ] : []),
        ]
      },
      {
        text: t('nav.scheduledEmails'),
        level: 1,
        path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/ScheduledEmails/ScheduledDashboardEmailList.aspx`,
        absoluteUrl: true,
        icon: <DraftsOutlinedIcon />,
        children: [
          {
            text: t('nav.dashboardEmails'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/ScheduledEmails/ScheduledDashboardEmailList.aspx`,
            absoluteUrl: true,
          },
          ...(hasModule(userModules, '_EposNowPayments') || hasModule(userModules, '_EposNowBalancePlatform')
            ? [
              {
                text: t('nav.payoutEmails'),
                level: 2,
                path: `${process.env.REACT_APP_LINK_BACK_OFFICE}hq/reports/payoutemail`,
                absoluteUrl: true,
              },
            ] : []),
        ]
      },
      {
        text: t('nav.classicReports'),
        path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CompleteTransactions.aspx`,
        absoluteUrl: true,
        icon: <SummarizeOutlinedIcon />,
        level: 1,
        children: [
          {
            text: t('nav.completedTransactions'),
            level: 2,
            path: `${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CompleteTransactions.aspx`,
            absoluteUrl: true,
          },
        ]
      }
    ] : []),
  ];

  const bottomStickyLinks: LinkInterface[] = [
    {
      text: t('nav.settings'),
      path: '/settings',
      icon: <SettingsOutlinedIcon />,
      level: 1,
      dataQaId: 'navSettings'
    },
  ]

  return (
    <>
      <SideNav
        key={location.pathname}
        links={links}
        bottomStickyLinks={bottomStickyLinks}
        theme={theme}
        animationsDisabled={animationsDisabled}
        isMobile={isMobile}
        isMediumDevice={isMediumDevice}
        iOS={iOS}
        openSideNav={openSideNav}
        showSideNav={showSideNav}
        toggleDrawer={toggleDrawer}
        locale={locale}
        location={location}
        linkNavigationFunction={linkNavigation}
        startSubItemsClosed
        backOfficeUrl={process.env.REACT_APP_LINK_BACK_OFFICE}
      />
      <TopNav
        openSideNav={openSideNav}
        showSideNav={showSideNav}
        animationsDisabled={animationsDisabled}
        theme={theme}
        isMobile={isMobile}
        toggleDrawer={toggleDrawer}
        user={{
          firstName: user?.FirstName || '',
          lastName: user?.LastName || '',
          email: user?.Email || '',
        }}
        locale={locale}
        currentProject="reporting"
        logout={logout}
        appsMenuEntries={appsMenu}
        cdnUrl={cdnUrl}
        accountUrl={accountUrl}
        appVersion={process.env.REACT_APP_BUILD_VERSION}
        isErrorAppsMenu={isErrorAppsMenu}
        onRetryAppsMenu={refetchAppsMenu}
      />
    </>
  );
};

export default NavigationComponents;
