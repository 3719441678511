import FilterListIcon from '@mui/icons-material/FilterList';
import Box from "@mui/material/Box";
import Chip from '@mui/material/Chip';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { ReactElement, RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Clear as ClearIcon, Search as SearchIcon, ViewWeekOutlined, MoreVert } from '@mui/icons-material';
import { UIContext } from '../context/UIContext';
import ToolButton from './ToolButton';

export interface TableChip {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onDelete?: () => void;
  adminView?: boolean;
}

interface EnhancedTableToolbarProps {
  onFilterClick: (event: React.MouseEvent<HTMLElement>) => void;
  isFilterOpen: boolean;
  chips?: TableChip[];
  filtersButtonRef?: RefObject<HTMLButtonElement>;
  extraButtons?: ReactElement;
  searchValue: string;
  setSearchValue: (s: string) => void;
  onColumnClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isColumnOpen?: boolean;
  tableHasTransactions?: boolean;
}

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { onFilterClick, isFilterOpen, chips, filtersButtonRef, extraButtons, searchValue, setSearchValue, onColumnClick, isColumnOpen, tableHasTransactions } = props;
  const { t } = useTranslation();
  const { colorMode, isMobile, isTablet } = React.useContext(UIContext);

  const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, [setSearchValue]);

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { sm: 2 },
          borderBottom: (isMobile || isTablet) ? 0 : (theme) =>
            `1px ${colorMode === 'light' ? theme?.palette?.grey?.['12p'] : theme?.palette?.grey?.['23p']
            } solid`,
        }}
      >
        <Box
          style={{ flex: '1 1 100%', display: 'inline-block', marginTop: '8px', marginBottom: '8px' }}
        >
          <TextField
            label={t('components.searchBar.searchResults')}
            size="small"
            sx={{marginRight: 'auto'}}
            id="search-input"
            InputProps={{
              inputProps: {'data-qa-id': "tableSearchInput"},
              endAdornment:
                searchValue === '' ? (
                  <InputAdornment position="end">
                    <SearchIcon htmlColor="icon.default" />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <IconButton
                      title={t('actions.delete')}
                      onClick={() => setSearchValue('')}
                      sx={{ padding: 0 }}
                      data-qa-id="tableSearchInputClearButton"
                    >
                      <ClearIcon htmlColor="icon.default" />
                    </IconButton>
                  </InputAdornment>
                ),
            }}
            value={searchValue}
            onChange={handleChangeSearch}
            fullWidth={(isMobile || isTablet)}
          />
        </Box>
        {tableHasTransactions &&
          <Box sx={{ marginRight: { xs: 0, md: '1.5em' } }}>
            <ToolButton
              iconElement={<ViewWeekOutlined />}
              tooltip={t('components.columns')}
              onClick={onColumnClick}
              text={t('components.columns')}
              active={isColumnOpen}
              dataQaId="tableColumnSelectionButton"
            />
          </Box>
        }
        {extraButtons}
          {(isMobile || isTablet) &&
          <Box sx={{paddingLeft: '0.5rem'}}>
            <ToolButton
              iconElement={<MoreVert />}
              onClick={onFilterClick}
              tooltip={t('components.filters.filterList')}
              active={isFilterOpen}
              buttonRef={filtersButtonRef}
              dataQaId="tableFiltersButtonMobile"
            /> 
          </Box>}
          {!(isMobile || isTablet) &&
          <Box>
            <ToolButton
              iconElement={<FilterListIcon />}
              tooltip={t('components.filters.filterList')}
              onClick={onFilterClick}
              text={t('components.filters.filters')}
              active={isFilterOpen}
              buttonRef={filtersButtonRef}
              dataQaId="tableFiltersButton"
            />
          </Box>
          }
      </Toolbar>
      {chips &&
        <Toolbar
          variant='dense'
          sx={{
            pl: { sm: 2 },
            pr: { sm: 2 },
          }}
        >
          <div
            style={{ flex: '1 1 100%', display: 'inline-block', marginTop: "12px", marginBottom: "12px" }}
          >
              <div style={{ flex: '1 1 100%', display: 'inline-block' }}>
                {chips.map((chip) => (
                  <Chip
                    key={`chip-${chip.label}`}
                    variant="filled"
                    label={chip.label}
                    onClick={chip.onClick}
                    onDelete={chip.onDelete}
                    sx={{ marginRight: '8px' }}
                  />
                ))}
              </div>
          </div>
        </Toolbar>
      }
    </>
  );
};
