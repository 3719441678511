import {format} from "date-fns";
import React, {useEffect, useState} from "react";
import { GridSortDirection } from "@mui/x-data-grid-pro";
import {TransactionsReportType} from "../../../api/transactions";
import {dateRange, DateRangeConfig} from "../../../helpers/dateUtils";
import useBulkExport from "../../../helpers/useBulkExport";
import {TransactionReportFilters} from "../../../models/transactions";
import {ExportDialog} from "./ExportDialog";

export interface ExportDialogWithApiProps {
    filters: TransactionReportFilters;
    reportType: TransactionsReportType;
    start: boolean;
    onComplete: () => void;
    sortBy: string;
    sortOrder: GridSortDirection;
    dateRangeConfig: DateRangeConfig;

    isAdmin?: boolean;
    search?: string;
}

export const ExportDialogWithApi = (props: ExportDialogWithApiProps) => {

    const {filters, reportType, start, onComplete, sortBy, sortOrder, dateRangeConfig, isAdmin, search} = props;
    const [open, setOpen] = useState(false);
    const [filename, setFilename] = useState<string | undefined>(undefined);

    const onCancel = () => {
        /* no op */
    }

    const onClose = () => {
        setOpen(false);
        if (onComplete) {
            onComplete();
        }
        setFilename(undefined);
    }

    function generateNewFilename() {
        const ts = format(new Date(), 'yyyyMMdd_kkmmss');
        setFilename(`Transaction Report_${ts}.csv`);
    }

    useEffect(() => {
        if (!start) {
            setFilename(undefined);
            return;
        }
        generateNewFilename();
        setOpen(true);
    }, [start]);

    const {
        startDate,
        endDate
    } = dateRange(filters.period, filters.periodFrom, filters.periodTo, undefined, dateRangeConfig);
    const {progress, fileUri, error} = useBulkExport({
        startDate, endDate, sortBy, sortOrder: sortOrder as string, filename, reportType, isAdmin,
        location: filters.location ? filters.location.id : undefined,
        locationArea: filters.locationArea ? filters.locationArea.id : undefined,
        staff: filters.staff ? filters.staff.id : undefined,
        device: filters.device ? filters.device.id : undefined,
        paymentMethod: filters.paymentMethod ? filters.paymentMethod.id : undefined,
        search: search || undefined,
        companyGuid: filters.companyGuid,
        hasActiveFlag: filters.hasActiveFlag,
        activeFlagCategory: filters.activeFlagCategory
    });

    return <ExportDialog open={open} progress={progress} fileUri={fileUri} filename={filename} error={error}
                         onCancel={onCancel} onClose={onClose} onRetry={() => {
        generateNewFilename();
    }}/>
}
